import { useCallback, useMemo, useRef, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import {
  useMotionValueEvent,
  useScroll,
  AnimatePresence,
  motion,
} from "framer-motion";
import { DateTime } from "luxon";

import CaretDoubleDown from "../Icons/CarotDoubleDown";
import CaretDoubleUp from "../Icons/CarotDoubleUp";
import ShiftMinimize from "./ShiftMinimize";

import AddCancel from "../Icons/AddCancel";
import { Shift } from "../../interface/shift";
import ShiftDropdown from "./ShiftDropdown";
import AddShiftBar from "./AddShiftBar";
import { TIME_ZONE } from "../../constants";
import TimeBar from "./TimeBar";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";

import { formatDisplayDate } from "../../utils/formatDate";
import { useRunOnChangeExt } from "../../hooks/runOnChange";

type DateBlockProps = {
  date: Date;
  shifts: Shift[];
  type: "inFocus" | "dayBefore" | "dayAfter";
  refetchShifts: () => void;
  isShiftsLoading: boolean;
  triggerBarReset: boolean;
  leaveDates: string[];
};

const AM_DROPDOWN_SHIFTS = [
  {
    from: {
      label: "8 AM",
      value: "8:00 AM",
    },
    to: {
      label: "12 PM",
      value: "12:00 PM",
    },
  },
  {
    from: {
      label: "6 AM",
      value: "6:00 AM",
    },
    to: {
      label: "10 AM",
      value: "10:00 AM",
    },
  },
  {
    from: {
      label: "7 AM",
      value: "7:00 AM",
    },
    to: {
      label: "11 AM",
      value: "11:00 AM",
    },
  },
  {
    from: {
      label: "7 AM",
      value: "7:00 AM",
    },
    to: {
      label: "2 PM",
      value: "2:00 PM",
    },
  },
];

const PM_DROPDOWN_SHIFTS = [
  {
    from: {
      label: "1 PM",
      value: "1:00 PM",
      dateValue: DateTime.fromObject({ hour: 13 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
    to: {
      label: "5 PM",
      value: "5:00 PM",
      dateValue: DateTime.fromObject({ hour: 17 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
  },
  {
    from: {
      label: "2 PM",
      value: "2:00 PM",
      dateValue: DateTime.fromObject({ hour: 14 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
    to: {
      label: "6 PM",
      value: "6:00 PM",
      dateValue: DateTime.fromObject({ hour: 18 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
  },
  {
    from: {
      label: "3 PM",
      value: "3:00 PM",
      dateValue: DateTime.fromObject({ hour: 15 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
    to: {
      label: "7 PM",
      value: "7:00 PM",
      dateValue: DateTime.fromObject({ hour: 19 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
  },
  {
    from: {
      label: "9 PM",
      value: "9:00 PM",
      dateValue: DateTime.fromObject({ hour: 21 }, { zone: TIME_ZONE })
        .setZone(TIME_ZONE)
        .toJSDate(),
    },
    to: {
      label: "1 AM",
      value: "1:00 AM",
      dateValue: DateTime.fromObject(
        { hour: 1 },
        { zone: TIME_ZONE }
      ).toJSDate(),
    },
  },
];

const getShiftsDropdown = (type: "am" | "pm" | undefined) => {
  if (type) {
    return type === "am" ? AM_DROPDOWN_SHIFTS : PM_DROPDOWN_SHIFTS;
  }
  return [];
};

const getBgColor = (type: "inFocus" | "dayBefore" | "dayAfter") => {
  if (type === "inFocus") {
    return "bg-primary";
  }
  return "bg-gray";
};

const getDateBarColor = (type: "inFocus" | "dayBefore" | "dayAfter") => {
  if (type === "inFocus") {
    return "bg-secondary";
  }
  if (type === "dayBefore") {
    return "bg-orange3";
  }
  if (type === "dayAfter") {
    return "bg-blue3";
  }
};

const getTextColor = (type: "inFocus" | "dayBefore" | "dayAfter") => {
  if (type === "inFocus") {
    return "text-white";
  }
  if (type === "dayBefore") {
    return "text-black";
  }
  if (type === "dayAfter") {
    return "text-black";
  }
};

const getScrollBarColor = (type: "inFocus" | "dayBefore" | "dayAfter") => {
  if (type === "inFocus") {
    return "bg-green2";
  }
  if (type === "dayBefore") {
    return "bg-gray2";
  }
  if (type === "dayAfter") {
    return "bg-gray2";
  }
};

const DateBlock = ({
  date,
  shifts,
  type,
  isShiftsLoading,
  refetchShifts,
  triggerBarReset,
  leaveDates,
}: DateBlockProps) => {
  const isLeave = useMemo(() => {
    const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
    return Boolean(
      leaveDates?.find((d: string) =>
        DateTime.fromISO(d).setZone(TIME_ZONE).equals(dateTime)
      )
    );
  }, [date, leaveDates]);

  const shiftDivRef = useRef<null | HTMLDivElement>(null);
  const shiftMinRefs = useRef<Array<null | HTMLDivElement>>(
    Array(shifts.length).fill(null)
  );

  if (shifts.length !== shiftMinRefs.current.length) {
    shiftMinRefs.current = Array(shifts.length).fill(null);
  }

  const { scrollYProgress } = useScroll({
    container: shiftDivRef,
  });
  const [scrollActive, setScrollActive] = useState({
    top: false,
    bottom: true,
  });

  const [mode, setMode] = useState<"read" | "add">("read");
  const [shiftType, setShiftType] = useState<"am" | "pm" | undefined>();
  const [selectedShiftIndex, setSeletectedShiftIndex] = useState<
    number | undefined
  >();

  const { setEditShiftMinId, setResponsiveWindow } = useEstimationContext();

  const toggleMode = useCallback(() => {
    if (!isLeave && !isShiftsLoading) {
      setMode(mode === "read" ? "add" : "read");
      if (mode === "add") {
        setShiftType(undefined);
        setSeletectedShiftIndex(undefined);
        setResponsiveWindow({ type: "dateView", props: {} });
      } else {
        setEditShiftMinId(undefined);
      }
    }
  }, [isLeave, isShiftsLoading, mode, setResponsiveWindow, setEditShiftMinId]);

  useRunOnChangeExt(triggerBarReset, () => {
    if (mode === "add") toggleMode();
  });

  const isOverflown = shiftDivRef.current
    ? shiftDivRef.current?.scrollHeight > shiftDivRef.current?.clientHeight
    : false;

  const [init, setInit] = useState(true);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest === 1 && init) {
      setScrollActive({ top: false, bottom: true });
      setInit(false);
    }

    if (latest <= 0) {
      setScrollActive({ top: false, bottom: true });
    }

    if (latest > 0 && latest < 1) {
      if (!scrollActive.top || !scrollActive.bottom) {
        setScrollActive({ top: true, bottom: true });
      }
    }

    if (latest > 0.99 && !init) {
      setScrollActive({ top: true, bottom: false });
    }
  });

  return (
    <div
      className={`relative transition-all duration-700 rounded-xl mx-auto ${
        type === "inFocus"
          ? "w-[97%] h-[85%] mt-[10%]"
          : "w-[93%] h-[75%] mt-[17%]"
      } ${getBgColor(
        type
      )} p-[12px] flex flex-col transition-all duration-700 ${
        isShiftsLoading ? "animate-pulseFast" : ""
      }`}
    >
      <div
        className={`rounded-xl px-[16px] py-[12px] z-10 ${getDateBarColor(
          type
        )} flex justify-between text-sm ${getTextColor(
          type
        )} font-medium items-center z-10 transition-all duration-700`}
      >
        {formatDisplayDate(date)}
        {!isLeave ? (
          <div
            className={`${
              !isShiftsLoading ? "cursor-pointer" : ""
            } rounded-lg hover:bg-white svg-hover-black transition-colors duration-700`}
            onClick={toggleMode}
          >
            <AddCancel
              type={mode === "read" ? "add" : "cancel"}
              stroke="white"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <AnimatePresence>
        {mode === "add" ? (
          <TimeBar
            type={type}
            shiftType={shiftType}
            setShiftType={setShiftType}
            setSeletectedShiftIndex={setSeletectedShiftIndex}
            date={date}
            selectedShift={
              selectedShiftIndex !== undefined
                ? {
                    from: getShiftsDropdown(shiftType)[selectedShiftIndex].from
                      .value,
                    to: getShiftsDropdown(shiftType)[selectedShiftIndex].to
                      .value,
                  }
                : undefined
            }
          />
        ) : (
          <></>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {shiftType && (
          <ShiftDropdown
            //TODO: change to suggested shifts
            shifts={getShiftsDropdown(shiftType)}
            activeShiftIndex={selectedShiftIndex}
            setActiveShiftIndex={(index: number) =>
              setSeletectedShiftIndex(index)
            }
            type={shiftType}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {selectedShiftIndex !== undefined && (
          <AddShiftBar
            resetSelectedShift={() => setSeletectedShiftIndex(undefined)}
            resetAddShift={async () => {
              setSeletectedShiftIndex(undefined);
              setShiftType(undefined);
              setMode("read");
              refetchShifts();
            }}
            date={date}
            from={
              getShiftsDropdown(shiftType)[selectedShiftIndex ?? 0].from.value
            }
            to={getShiftsDropdown(shiftType)[selectedShiftIndex ?? 0].to.value}
          />
        )}
      </AnimatePresence>
      {isOverflown &&
        scrollActive.top &&
        selectedShiftIndex === undefined &&
        Boolean(shifts.length) && (
          <div
            className={`absolute cursor-pointer ${
              mode === "read"
                ? "top-[70px]"
                : shiftType
                ? "top-[197px]"
                : "top-[118px]"
            } left-0 w-[100%] flex justify-center ${getScrollBarColor(
              type
            )} rounded-t-xl z-10`}
            onClick={() => {
              if (shiftDivRef.current) {
                shiftDivRef.current.scrollTop = 0;
              }
            }}
          >
            <CaretDoubleUp />
          </div>
        )}
      <motion.div
        layout
        className="overflow-y-scroll overscroll-y-none scroll-smooth no-scrollbar grow mt-3 flex flex-col gap-2"
        ref={(elem) => {
          shiftDivRef.current = elem;
        }}
      >
        {shifts.length && !isLeave ? (
          shifts.map((shift, index) => {
            return (
              <ShiftMinimize
                key={shift._id}
                innerRef={(el) => {
                  shiftMinRefs.current[index] = el;
                }}
                date={date}
                slotsFilled={shift.doctorsFilled}
                slotsCount={shift.totalDoctorsRequired}
                time={shift.time}
                type={shift.type}
                shiftId={shift._id}
                dateBlockType={type}
                refetchShifts={refetchShifts}
                isFetching={isShiftsLoading}
                resetAddShift={() => {
                  setSeletectedShiftIndex(undefined);
                  setShiftType(undefined);
                  setResponsiveWindow({ type: "dateView", props: {} });
                  setMode("read");
                }}
              />
            );
          })
        ) : isShiftsLoading ? (
          <div className="m-auto col-span-2">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : (
          <div className="col-span-2 flex justify-center items-center h-[100%] font-medium">
            {isLeave ? "Date Blocked" : "No Shifts Added"}
          </div>
        )}
      </motion.div>
      {isOverflown && scrollActive.bottom ? (
        <div
          className={`absolute cursor-pointer bottom-0 left-0 w-[100%] flex justify-center ${getScrollBarColor(
            type
          )} rounded-b-xl`}
          onClick={() => {
            if (shiftDivRef.current) {
              shiftDivRef.current.scrollTop = shiftDivRef.current.scrollHeight;
            }
          }}
        >
          <CaretDoubleDown />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateBlock;
