import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";

import DayPointsBlock, { PointsData } from "./DayPointsBlock";
import { TimeBlock } from "../Dashboard/DayView";
import CarotUp from "../Icons/CarotUp";
import CarotDown from "../Icons/CarotDown";
import { useRunOnChange } from "../../hooks/runOnChange";
import { useUpdateShiftPointsMutation } from "../../api/preferencesApi";
import handleResponse from "../../utils/handleResponse";
import clone from "../../utils/fastClone";
import deepEqual from "deep-equal";
import removeKeys from "../../utils/removeKeys";

export type POINTS = {
  key: string;
  parentKey: string;
  label: string;
  labelInput: string;
}[];

const POINTS_BLOCK: {
  key: "weekdays" | "weekends" | "holidays";
  label: string;
  points: POINTS;
  dataKey: string;
}[] = [
  {
    key: "weekdays",
    label: "Weekdays",
    points: [
      {
        key: "monday",
        parentKey: "dayOfWeek",
        label: "Mo",
        labelInput: "Monday",
      },
      {
        key: "tuesday",
        parentKey: "dayOfWeek",
        label: "Tu",
        labelInput: "Tuesday",
      },
      {
        key: "wednesday",
        parentKey: "dayOfWeek",
        label: "We",
        labelInput: "Wednesday",
      },
      {
        key: "thursday",
        parentKey: "dayOfWeek",
        label: "Th",
        labelInput: "Thursday",
      },
      {
        key: "friday",
        parentKey: "dayOfWeek",
        label: "Fr",
        labelInput: "Friday",
      },
    ],
    dataKey: "dayOfWeek",
  },
  {
    key: "weekends",
    label: "Weekends",
    points: [
      {
        key: "saturday",
        parentKey: "dayOfWeek",
        label: "Saturday",
        labelInput: "Saturday",
      },
      {
        key: "sunday",
        parentKey: "dayOfWeek",
        label: "Sunday",
        labelInput: "Sunday",
      },
    ],
    dataKey: "dayOfWeek",
  },
  {
    key: "holidays",
    label: "Public Holidays",
    points: [
      {
        key: "eve",
        parentKey: "holidays",
        label: "Eve of Holiday",
        labelInput: "Eve of Holiday",
      },
      {
        key: "on",
        parentKey: "holidays",
        label: "Holiday",
        labelInput: "Holiday",
      },
    ],
    dataKey: "holidays",
  },
];

const TIME_BLOCKS: { key: TimeBlock; label: string }[] = [
  {
    key: "morning",
    label: "Morning",
  },
  {
    key: "noon",
    label: "Noon",
  },
  {
    key: "night",
    label: "Night",
  },
];

const ShiftPointsBlock = ({
  shiftLabel,
  isActive,
  triggerReset,
  pointsData,
  setIsActive,
  isLoading,
  refetch,
}: {
  shiftLabel: string;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  triggerReset: boolean;
  pointsData: PointsData[];
  isLoading: boolean;
  refetch: () => void;
}) => {
  const [showTimeBlocks, setShowTimeBlocks] = useState(false);

  const isTimeBlocksDiffer = useMemo(() => {
    return !pointsData.reduce<{
      prevVal:
        | {
            dayOfWeek: Record<string, number | undefined>;
            holidays: Record<string, number | undefined>;
          }
        | undefined;
      isEqual: boolean;
    }>(
      (obj, point) => {
        if (!obj.isEqual) {
          return { prevVal: undefined, isEqual: false };
        }

        const comparePoint = {
          dayOfWeek: removeKeys(point.dayOfWeek, ["id", "_id"]),
          holidays: removeKeys(point.holidays, ["id", "_id"]),
        };

        if (!obj.prevVal) {
          return {
            prevVal: comparePoint,
            isEqual: obj.isEqual,
          };
        }

        return {
          prevVal: comparePoint,
          isEqual: deepEqual(comparePoint, obj.prevVal, { strict: true }),
        };
      },
      { prevVal: undefined, isEqual: true }
    ).isEqual;
  }, [pointsData]);

  const [activeTimeBlock, setActiveTimeBlock] = useState<TimeBlock | undefined>(
    isTimeBlocksDiffer ? "morning" : undefined
  );

  const [showPointsBlock, setShowPointsBlock] = useState<
    Record<string, boolean>
  >({
    weekdays: false,
    weekends: false,
    holidays: false,
  });

  useRunOnChange(triggerReset, () => {
    setShowTimeBlocks(false);
    setShowPointsBlock({
      weekdays: false,
      weekends: false,
      holidays: false,
    });
  });

  const [updateShiftPoint, { isLoading: isUpdateLoading }] =
    useUpdateShiftPointsMutation();

  const activeTimeBlockPoint = pointsData.find(
    (pD) => pD.timeBlock === activeTimeBlock
  );

  return (
    <div
      className={`p-2 rounded-xl flex flex-col gap-2 ${
        isActive
          ? "bg-primary border-green4 border-2 w-[40%]"
          : "bg-gray w-[30%]"
      } ${
        isLoading || isUpdateLoading ? "animate-pulseFast" : ""
      } h-fit transition-all duration-700`}
    >
      <div>
        <div
          className={`w-ful rounded-xl h-[45px] flex justify-center items-center cursor-pointer ${
            isActive ? "bg-green4 text-white" : "bg-white"
          }`}
          onClick={() => {
            if (isActive) {
              if (!isTimeBlocksDiffer) {
                setShowTimeBlocks(!showTimeBlocks);
              }
            } else {
              setIsActive(true);
            }
          }}
        >
          <div className="w-fit font-semibold text-xl">{shiftLabel}</div>
          {isActive && !isTimeBlocksDiffer && (
            <div className="ml-4">
              {showTimeBlocks ? <CarotUp /> : <CarotDown />}
            </div>
          )}
        </div>
        <AnimatePresence>
          {(showTimeBlocks || isTimeBlocksDiffer) && (
            <motion.div
              className="flex justify-between px-4 pt-2 w-full"
              key="time-block"
              initial={{ height: 0, zIndex: 0 }}
              animate={{ height: "auto", zIndex: 1 }}
              exit={{ height: 0, zIndex: 0 }}
              transition={{ duration: 0.08 }}
            >
              {TIME_BLOCKS.map((tb) => (
                <div
                  key={tb.key}
                  className={` font-semibold ${
                    activeTimeBlock === tb.key && isActive
                      ? "bg-green4 text-white px-6"
                      : `bg-white text-gray3 ${isActive ? "px-4" : "px-2"}`
                  } rounded-md text-sm py-2 cursor-pointer`}
                  onClick={() => {
                    if (isActive) {
                      if (isTimeBlocksDiffer) {
                        setActiveTimeBlock(tb.key);
                      } else {
                        setActiveTimeBlock(
                          tb.key === activeTimeBlock ? undefined : tb.key
                        );
                      }
                    }
                  }}
                >
                  {tb.label}
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {POINTS_BLOCK.map((pb) => (
        <DayPointsBlock
          key={pb.key}
          pointsType={pb.key}
          isActive={isActive}
          isPointsShowBlock={showPointsBlock[pb.key]}
          setIsPointsShowBlock={(isActive: boolean) => {
            setShowPointsBlock({
              ...showPointsBlock,
              [pb.key]: isActive,
            });
          }}
          pointsData={activeTimeBlockPoint ?? pointsData[0]}
          label={pb.label}
          points={pb.points}
          triggerReset={triggerReset}
          updateShiftPoint={async (key: string, point: number) => {
            const payload = clone({
              shiftPointsIds: activeTimeBlockPoint
                ? [activeTimeBlockPoint._id]
                : pointsData.map((pD) => pD._id),
              dayOfWeek: removeKeys(
                activeTimeBlockPoint
                  ? activeTimeBlockPoint.dayOfWeek
                  : pointsData[0].dayOfWeek,
                ["id", "_id"]
              ),
              holidays: removeKeys(
                activeTimeBlockPoint
                  ? activeTimeBlockPoint.holidays
                  : pointsData[0].holidays,
                ["id", "_id"]
              ),
            });

            payload[pb.dataKey as "dayOfWeek" | "holidays"][key] = point;
            const response = await updateShiftPoint(payload);
            refetch();
            handleResponse(response, "Point updated successfully.");
          }}
        />
      ))}
    </div>
  );
};

export default ShiftPointsBlock;
