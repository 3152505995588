import useMonthCalendar from "@/hooks/useMonthCalendar";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useRef,
  useState,
} from "react";

export const TableViewContext = createContext<{
  month: number;
  calenderBack: () => void;
  year: number;
  calenderNext: () => void;
  tableState: "location" | "requests" | "users";
  setTableState: Dispatch<SetStateAction<"location" | "requests" | "users">>;
  isShiftTimeToggle: boolean;
  setIsShiftTimeToggle: Dispatch<SetStateAction<boolean>>;
  isOff: boolean;
  setIsOff: Dispatch<SetStateAction<boolean>>;
  activeDate: Date | undefined;
  setActiveDate: Dispatch<SetStateAction<Date | undefined>>;
  scrollRef:
    | React.MutableRefObject<{
        divSideBar: HTMLDivElement | null;
        divTable: HTMLDivElement | null;
        isSyncingSideBarScroll: boolean;
        isSyncingTableScroll: boolean;
      }>
    | undefined;
  onScrollHandlers: {
    sideBar: () => void;
    table: () => void;
  };
}>({
  month: 1,
  calenderBack: () => undefined,
  year: 1,
  calenderNext: () => undefined,
  tableState: "users",
  setTableState: () => undefined,
  isShiftTimeToggle: false,
  setIsShiftTimeToggle: () => undefined,
  isOff: false,
  setIsOff: () => undefined,
  activeDate: undefined,
  setActiveDate: () => undefined,
  scrollRef: undefined,
  onScrollHandlers: { sideBar: () => undefined, table: () => undefined },
});

export const TableViewContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  // Use the global calendar for initial values OPTIONAL
  const { month, calenderBack, year, calenderNext } = useMonthCalendar({});
  const [isShiftTimeToggle, setIsShiftTimeToggle] = useState(false);
  const [isOff, setIsOff] = useState(false);
  const [activeDate, setActiveDate] = useState<Date | undefined>();

  const [tableState, setTableState] = useState<
    "location" | "requests" | "users"
  >("users");

  const scrollRef = useRef<{
    divSideBar: HTMLDivElement | null;
    divTable: HTMLDivElement | null;
    isSyncingSideBarScroll: boolean;
    isSyncingTableScroll: boolean;
  }>({
    divSideBar: null,
    divTable: null,
    isSyncingSideBarScroll: false,
    isSyncingTableScroll: false,
  });

  const sideBarHandler = () => {
    if (!scrollRef.current.isSyncingSideBarScroll) {
      scrollRef.current.isSyncingTableScroll = true;
      scrollRef.current.divTable!.scrollTop =
        scrollRef.current.divSideBar!.scrollTop;
    }
    scrollRef.current.isSyncingTableScroll = false;
  };

  const tableHandler = () => {
    if (!scrollRef.current.isSyncingTableScroll) {
      scrollRef.current.isSyncingSideBarScroll = true;
      scrollRef.current.divSideBar!.scrollTop =
        scrollRef.current.divTable!.scrollTop;
    }
    scrollRef.current.isSyncingSideBarScroll = false;
  };

  return (
    <TableViewContext.Provider
      value={{
        month,
        calenderBack,
        year,
        calenderNext,
        tableState,
        setTableState,
        isShiftTimeToggle,
        setIsShiftTimeToggle,
        isOff,
        setIsOff,
        activeDate,
        setActiveDate,
        scrollRef,
        onScrollHandlers: { sideBar: sideBarHandler, table: tableHandler },
      }}
    >
      {children}
    </TableViewContext.Provider>
  );
};
