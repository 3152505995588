import { create } from "zustand";

interface LocationState {
  activeId: string;
  activeLocation: any;
  setActiveLocation: (activeLocation: any) => void;
}

export const useLocation = create<LocationState>((set) => ({
  activeId: "",
  activeLocation: undefined,
  setActiveLocation: (activeLocation) =>
    set({ activeLocation, activeId: activeLocation?._id ?? '' }),
}));

interface CreateLocationState {
  label: string;
  setLocationLabel: (name: string) => void;
  shortLabel: string;
  setLocationShortLabel: (name: string) => void;
  seniorities: number[];
  setSeniorities: (seniorities: number[]) => void;
  groups: any[];
  setGroups: (groups: any[]) => void;
  closedDates: string[];
  setClosedDates: (closedDates: string[]) => void;
}

export const useCreateLocation = create<CreateLocationState>((set) => ({
  label: "",
  setLocationLabel: (name) => set({ label: name }),
  shortLabel: "",
  setLocationShortLabel: (name) => set({ shortLabel: name }),
  seniorities: [],
  setSeniorities: (seniorities) => set({ seniorities }),
  groups: [],
  setGroups: (groups) => set({ groups }),
  closedDates: [],
  setClosedDates: (closedDates) => set({ closedDates }),
}));
