import { useEffect } from "react";
import { NUMBER_OF_DOCTORS, ROW_HEIGHT, SENIORITIES } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import DoctorCard from "../Cards/SmallDoctorCard";
import SearchTwo from "../Icons/SearchTwo";
import { useSeniority } from "@/store/seniority.state";

const SideBar = () => {
  const { scrollRef, onScrollHandlers } = useTableViewContext();

  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  useEffect(() => {
    if (scrollRef && scrollRef.current.divSideBar) {
      scrollRef.current.divSideBar.addEventListener(
        "scroll",
        onScrollHandlers.sideBar
      );
    }
    const elemCopy = scrollRef!.current.divSideBar;

    return () => {
      elemCopy?.removeEventListener("scroll", onScrollHandlers.sideBar);
    };
  }, [onScrollHandlers.sideBar, scrollRef]);

  return (
    <div className="w-full bg-gray8 h-full flex flex-col">
      <div className={`h-[${ROW_HEIGHT}px]`}>
        <div className="h-[50%] w-full bg-gray4 flex">
          <div className="flex flex-col-reverse px-1">
            <div className="flex gap-[2px]">
              {SENIORITIES.map((seniority) => (
                <div
                  className={`bg-[${seniority.colorCode}] rounded-t-lg ${
                    activeSeniorityId === seniority.id ? "text-sm" : "text-xs"
                  } font-medium text-white h-fit p-1 cursor-pointer`}
                  onClick={() => {
                    setActiveId(seniority.id);
                  }}
                >
                  {activeSeniorityId === seniority.id
                    ? seniority.label
                    : seniority.shortLabel}
                </div>
              ))}
              <div
                className={`bg-black rounded-t-lg ${
                  activeSeniorityId === undefined ? "text-sm" : "text-xs"
                } font-medium text-white h-fit p-1 cursor-pointer`}
                onClick={() => {
                  setActiveId(undefined);
                }}
              >
                All
              </div>
            </div>
          </div>
          <div className="bg-gray8 rounded-t-2xl flex-grow p-1 relative flex">
            <input
              placeholder="Search"
              className="bg-white rounded-l-full rounded-r-full w-full outline-none text-[9px] font-medium"
            />
            <div className="absolute right-0">
              <SearchTwo />
            </div>
          </div>
        </div>
        <div className="h-[50%]"></div>
      </div>
      <div
        className="overflow-y-auto px-1 flex-grow h-0 no-scrollbar"
        ref={(ref) => {
          scrollRef!.current.divSideBar = ref;
        }}
      >
        {Array(NUMBER_OF_DOCTORS)
          .fill(0)
          .map((_, index) => (
            <div
              className={`h-[${ROW_HEIGHT}px] flex items-center`}
              key={index} // Replace with doctor id
            >
              <div className="h-[80%] w-full">
                <DoctorCard name={"Test Name"} seniority={1} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideBar;
