import { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";
import OuterWindow from "../OuterWindow";
import ShiftCard from "../ShiftCard";
import { InfinitySpin } from "react-loader-spinner";
import { sortShifts, useGetShiftsSingle } from "../../../hooks/estimation/useGetShifts";
import { formatDisplayDate } from "../../../utils/formatDate";
import { Shift } from "../../../interface/shift";

type DateViewWindowProps = {
  activeDate: Date;
  nextDate: Date;
};

const DateViewWindow: FC<DateViewWindowProps> = (props) => {
  const { nextDate } = props;

  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
  } = useGetShiftsSingle({
    activeDate: nextDate,
  });

  return (
    <OuterWindow
      className="bg-blue3 rounded-xl flex flex-col h-full"
      title={formatDisplayDate(nextDate)}
      bodyClass="flex-grow overflow-auto !h-full"
      bodyChildNode={
        !(isShiftsLoading || (!shiftData && isShiftsFetching)) ? (
          shiftData && shiftData.length > 0 ? (
            <div className="grid grid-cols-2 gap-4">
              {[...shiftData].sort(sortShifts).map((shift: Shift) => (
                <ShiftCard
                  isFetching={isShiftsFetching}
                  date={nextDate}
                  type={shift.type}
                  key={`${shift._id}`}
                  shiftId={shift._id}
                  time={shift.time}
                  slots={shift.slots}
                  windowType={"responsive"}
                />
              )) ?? []}
            </div>
          ) : (
            <div className="col-span-2">
              <AnimatePresence>
                <motion.div
                  key="noDataTransition"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 210 }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.8 }}
                  className="font-medium flex justify-center items-center h-[210px]"
                >
                  No Shifts Added
                </motion.div>
              </AnimatePresence>
            </div>
          )
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        )
      }
    />
  );
};

export default DateViewWindow;
