const User = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M7.92697 8.67021C6.86224 8.67021 5.95077 8.2911 5.19256 7.53289C4.43435 6.77468 4.05524 5.86321 4.05524 4.79848C4.05524 3.73376 4.43435 2.82229 5.19256 2.06408C5.95077 1.30586 6.86224 0.926758 7.92697 0.926758C8.99169 0.926758 9.90316 1.30586 10.6614 2.06408C11.4196 2.82229 11.7987 3.73376 11.7987 4.79848C11.7987 5.86321 11.4196 6.77468 10.6614 7.53289C9.90316 8.2911 8.99169 8.67021 7.92697 8.67021ZM0.183517 16.4137V13.7035C0.183517 13.155 0.324674 12.6508 0.606987 12.1911C0.889301 11.7313 1.26437 11.3804 1.73221 11.1384C2.7324 10.6383 3.74873 10.2633 4.78119 10.0132C5.81365 9.76316 6.86224 9.63814 7.92697 9.63814C8.99169 9.63814 10.0403 9.76316 11.0727 10.0132C12.1052 10.2633 13.1215 10.6383 14.1217 11.1384C14.5896 11.3804 14.9646 11.7313 15.2469 12.1911C15.5293 12.6508 15.6704 13.155 15.6704 13.7035V16.4137H0.183517ZM2.11938 14.4778H13.7346V13.7035C13.7346 13.526 13.6902 13.3647 13.6015 13.2195C13.5127 13.0743 13.3958 12.9614 13.2506 12.8807C12.3795 12.4451 11.5002 12.1185 10.613 11.9007C9.72571 11.6829 8.83037 11.574 7.92697 11.574C7.02356 11.574 6.12823 11.6829 5.24096 11.9007C4.35369 12.1185 3.47448 12.4451 2.60335 12.8807C2.45816 12.9614 2.3412 13.0743 2.25247 13.2195C2.16374 13.3647 2.11938 13.526 2.11938 13.7035V14.4778ZM7.92697 6.73434C8.45933 6.73434 8.91506 6.54479 9.29417 6.16569C9.67328 5.78658 9.86283 5.33084 9.86283 4.79848C9.86283 4.26612 9.67328 3.81039 9.29417 3.43128C8.91506 3.05217 8.45933 2.86262 7.92697 2.86262C7.39461 2.86262 6.93887 3.05217 6.55976 3.43128C6.18066 3.81039 5.9911 4.26612 5.9911 4.79848C5.9911 5.33084 6.18066 5.78658 6.55976 6.16569C6.93887 6.54479 7.39461 6.73434 7.92697 6.73434Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default User;
