import { FC } from "react";
import { useDraggable } from "@dnd-kit/core";

import DoctorCard from "../Cards/DoctorCard";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useCreateScheduleMutation } from "../../api/rosterApi";
import { useEstDndContext } from "../../hooks/context/useEstDndContext";
import handleResponse from "../../utils/handleResponse";
import { ShiftType } from "../../interface/shift";

export interface DoctorCardProps {
  name: string;
  doctor: any;
  doctorId: string;
  isActive: boolean;
  canAssign?: boolean;
  canDrag: boolean;
  isFetching: boolean;
  plusClickHandler?: () => Promise<void>;
  onClickHandler: () => void;
  onDoubleClickHandler?: () => void;
  onScheduleAdd?: () => Promise<void>;
  deleteHandler?: () => void;
  status?: "available" | "warning" | "unavailable";
  assignedShiftType: ShiftType | "none";
  seniority: "3" | "2" | "1";
  className?: string;
  isDisabled?: boolean;
  type:
    | "doctor-filter"
    | "doctor-filter-no-shift"
    | "user-management"
    | "group-management";
}

const DraggableDoctorCard: FC<DoctorCardProps> = (props) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: props.doctorId,
    data: { doctor: props.doctor },
    disabled: props.isFetching,
  });

  const { editShiftId } = useEstimationContext();
  const { activeDoctor } = useEstDndContext();

  const [createSchedule, { isLoading }] = useCreateScheduleMutation();

  const [_, { isLoading: isLoadingDragAndDrop }] = useCreateScheduleMutation({
    fixedCacheKey: "drag-and-drop",
  });

  return (
    <div ref={setNodeRef}>
      <DoctorCard
        {...props}
        extraLeftCardProps={{
          ...(props.canDrag ? listeners : {}),
          ...(props.canDrag ? attributes : {}),
        }}
        isFetching={
          props.isFetching ||
          isLoading ||
          (isLoadingDragAndDrop && activeDoctor?._id === props.doctorId)
        }
        plusClickHandler={async () => {
          const response = await createSchedule({
            shiftId: editShiftId,
            doctorId: props.doctor._id,
          });
          handleResponse(response, "Schedule added", () => {
            props.onScheduleAdd?.();
          });
        }}
        isDisabled={isDragging}
      />
    </div>
  );
};

export default DraggableDoctorCard;
