import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

const PAGINATION = 8;

const Pagination = ({
  isLoading,
  page,
  totalPages,
  setPage,
  onPageChange,
}: {
  isLoading: boolean;
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
  onPageChange: () => void;
}) => {
  const paginationArray = (() => {
    if (totalPages < PAGINATION) {
      return Array(totalPages)
        .fill(undefined)
        .map((_, index) => index + 1);
    } else if (page < 4) {
      return (
        Array(5)
          .fill(undefined)
          .map((_, index) => index + 1) as (string | number)[]
      ).concat(["...", totalPages]);
    } else if (page > totalPages - 4) {
      return [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      return [1, "...", page - 1, page, page + 1, "...", totalPages];
    }
  })();

  return (
    <div className="flex justify-center items-center py-8 relative z-[5] bg-white">
      <div
        className="flex items-center bg-white border-2 border-gray cursor-pointer rounded-xl h-[35px] w-[35px] mr-3"
        onClick={() => {
          if (!isLoading && page > 1) {
            setPage(page - 1);
            onPageChange();
          }
        }}
      >
        <IoChevronBackOutline size={20} className="text-black mx-auto" />
      </div>
      {paginationArray.map((pageNumber, index) => (
        <div
          key={index}
          className={`px-2 font-medium ${
            page !== pageNumber && typeof pageNumber === "number"
              ? "cursor-pointer"
              : ""
          } ${
            page !== pageNumber
              ? "text-[#D3D3D3] mx-[2px]"
              : "border-x-2 border-gray6 rounded"
          }`}
          onClick={() => {
            if (page !== pageNumber && typeof pageNumber === "number") {
              setPage(pageNumber);
            }
          }}
        >
          {pageNumber}
        </div>
      ))}
      <div
        className="flex items-center bg-white border-2 border-gray cursor-pointer rounded-xl h-[35px] w-[35px] ml-3"
        onClick={() => {
          if (!isLoading && page < totalPages) {
            setPage(page + 1);
            onPageChange();
          }
        }}
      >
        <IoChevronForwardOutline size={20} className="text-black mx-auto" />
      </div>
    </div>
  );
};

export default Pagination;
