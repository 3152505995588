const Search = ({ className = "", ...props }: any) => {
  return (
    <svg
      {...props}
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.97422 15.2502C7.73314 15.2502 6.51994 14.8822 5.48802 14.1927C4.4561 13.5032 3.65182 12.5231 3.17688 11.3765C2.70194 10.2299 2.57767 8.96824 2.81979 7.75101C3.06192 6.53378 3.65955 5.41568 4.53713 4.5381C5.4147 3.66053 6.5328 3.06289 7.75003 2.82077C8.96726 2.57865 10.229 2.70291 11.3756 3.17785C12.5222 3.65279 13.5022 4.45708 14.1917 5.48899C14.8812 6.52091 15.2492 7.73412 15.2492 8.9752C15.2492 9.79924 15.0869 10.6152 14.7716 11.3765C14.4562 12.1379 13.994 12.8296 13.4113 13.4123C12.8286 13.995 12.1369 14.4572 11.3756 14.7725C10.6142 15.0879 9.79827 15.2502 8.97422 15.2502ZM8.97422 3.95853C7.98532 3.95853 7.01862 4.25178 6.19637 4.80118C5.37412 5.35059 4.73326 6.13148 4.35482 7.04511C3.97639 7.95875 3.87737 8.96408 4.0703 9.93398C4.26322 10.9039 4.73943 11.7948 5.43869 12.4941C6.13795 13.1933 7.02886 13.6695 7.99877 13.8625C8.96867 14.0554 9.97401 13.9564 10.8876 13.5779C11.8013 13.1995 12.5822 12.5586 13.1316 11.7364C13.681 10.9141 13.9742 9.94744 13.9742 8.95853C13.9742 7.63245 13.4474 6.36068 12.5098 5.423C11.5721 4.48532 10.3003 3.95853 8.97422 3.95853Z"
        fill="#838383"
      />
      <path
        d="M16.6663 17.2913C16.5842 17.2917 16.5028 17.2757 16.427 17.2442C16.3511 17.2127 16.2823 17.1664 16.2246 17.108L12.783 13.6663C12.6726 13.5478 12.6125 13.3911 12.6153 13.2292C12.6182 13.0673 12.6838 12.9128 12.7983 12.7983C12.9128 12.6838 13.0673 12.6182 13.2292 12.6153C13.3911 12.6125 13.5478 12.6726 13.6663 12.783L17.108 16.2246C17.225 16.3418 17.2908 16.5007 17.2908 16.6663C17.2908 16.8319 17.225 16.9908 17.108 17.108C17.0503 17.1664 16.9815 17.2127 16.9056 17.2442C16.8298 17.2757 16.7484 17.2917 16.6663 17.2913Z"
        fill="#838383"
      />
    </svg>
  );
};

export default Search;
