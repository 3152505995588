import OuterWindow from "./OuterWindow";
import ShiftCard from "./ShiftCard";
import { motion } from "framer-motion";
import { InfinitySpin } from "react-loader-spinner";
import {
  sortShifts,
  useGetShiftsSingle,
} from "../../hooks/estimation/useGetShifts";
import { formatDisplayDate } from "../../utils/formatDate";
import { Shift } from "../../interface/shift";

type SecondaryMonitorProps = {
  prevDate: Date;
  className?: string;
};

const SecondaryMonitor = ({ prevDate, className }: SecondaryMonitorProps) => {
  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
  } = useGetShiftsSingle({ activeDate: prevDate });

  return (
    <>
      <OuterWindow
        className={`h-full ${className}`}
        title={formatDisplayDate(prevDate)}
        headerClassName="bg-yellow1"
        bodyClass="flex flex-col gap-y-3 flex-grow overflow-auto"
        titleClassName="transition duration-500 "
        bodyChildNode={
          !(isShiftsLoading || (!shiftData && isShiftsFetching)) ? (
            shiftData && shiftData.length > 0 ? (
              <>
                {[...shiftData]
                  .sort(sortShifts)
                  .map((shift: Shift) => (
                    <ShiftCard
                      isFetching={isShiftsFetching}
                      date={prevDate}
                      type={shift.type}
                      key={`${shift._id}`}
                      shiftId={shift._id}
                      time={shift.time}
                      slots={shift.slots}
                      windowType={"secondary"}
                    />
                  )) ?? []}
              </>
            ) : (
              <motion.div
                key="noDataTransition"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 210 }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.8 }}
                className="font-medium flex justify-center items-center h-[210px]"
              >
                No Shifts Added
              </motion.div>
            )
          ) : (
            <div className="m-auto col-span-2">
              <InfinitySpin width="200" color="#67823A" />
            </div>
          )
        }
      />
    </>
  );
};

export default SecondaryMonitor;
