const RoundedTick = ({
  active,
  size = { width: "20", height: "20" },
}: {
  active: boolean;
  size?: { width: string; height: string };
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 13.33336 13.33329"
      fill="none"
      className="group/tick"
    >
      <path
        d="M 13.33336,6.66667 C 13.33336,2.98477 10.34856,0 6.66666,0 2.98476,0 0,2.98477 0,6.66667 c 0,3.68182 2.98476,6.66662 6.66666,6.66662 3.6819,0 6.6667,-2.9848 6.6667,-6.66662 z"
        fill={active ? "#27AE60" : "#E0E0E0"}
        className={!active ? "group-hover/tick:fill-[#27AE60]" : ""}
      />
      <path
        d="m 4,7.00002 1.66666,1.66667 3.6667,-4"
        stroke={active ? "#FFFFFF" : "#4F4F4F"}
        className={!active ? "group-hover/tick:stroke-[#FFFFFF]" : ""}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedTick;
