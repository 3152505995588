import { create } from "zustand";

interface SeniorityState {
  activeId: number | undefined;
  setActiveId: (id: number | undefined) => void;
  disabledSeniorities: number[];
  setDisabled: (id: number, bool: boolean) => void;
}

export const useSeniority = create<SeniorityState>((set) => ({
  activeId: 1,
  setActiveId: (id) => set({ activeId: id }),
  disabledSeniorities: [],
  setDisabled: (id, isDisabled) => {
    set((state) => {
      let newDisabledSeniorities: number[] = state.disabledSeniorities;
      if (isDisabled && !newDisabledSeniorities.includes(id)) {
        newDisabledSeniorities.push(id);
      } else if (!isDisabled && newDisabledSeniorities.includes(id)) {
        newDisabledSeniorities = newDisabledSeniorities.filter(
          (id_) => id !== id_
        );
      }
      return { disabledSeniorities: newDisabledSeniorities };
    });
  },
}));
