import TableViewLayout from "@/layouts/TableView";
import { TableViewContextProvider } from "@/context/TableViewContext";

import TopNavBar from "@/components/TableView/TopNavBar";
import SideBar from "@/components/TableView/SideBar";
import Table from "@/components/TableView/Table";
import MenuOptions from "@/components/TableView/MenuOptions";
import { useGetLocationsQuery } from "@/api/locationApi";
import { useRunOnChangeExt } from "@/hooks/runOnChange";
import { useLocation } from "@/store/location.store";
import { InfinitySpin } from "react-loader-spinner";

const TableView = () => {
  const { isLoading: isLocationLoading, data: locationsData } =
    useGetLocationsQuery({
      seniority: 4,
      all: true,
    });
  const { setActiveLocation } = useLocation();

  useRunOnChangeExt(locationsData, () => {
    if (locationsData?.locations.length) {
      setActiveLocation(locationsData.locations[0]);
    }
  });

  return (
    <TableViewContextProvider>
      <TableViewLayout>
        {isLocationLoading ? (
          <InfinitySpin width="200px" color="#67823A" />
        ) : (
          <>
            <div className="flex w-full">
              <div className="w-[17%]">
                <MenuOptions />
              </div>
              <div className="w-[83%]">
                <TopNavBar />
              </div>
            </div>
            <div className="flex flex-grow w-full">
              <div className="w-[17%]">
                <SideBar />
              </div>
              <div className="w-[83%] pr-5 bg-gray4">
                <Table />
              </div>
            </div>
          </>
        )}
      </TableViewLayout>
    </TableViewContextProvider>
  );
};

export default TableView;
