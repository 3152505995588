import { create } from "zustand";
import { TIME_ZONE } from "../constants";
import { DateTime } from "luxon";

interface CalendarState {
  month: number;
  year: number;
  setMonth: (month: number) => void;
  setYear: (year: number) => void;
}

/**
 * @description This global state is for the dashboard page. Could be replaced
 */
export const useCalendar = create<CalendarState>((set) => ({
  month: DateTime.now().setZone(TIME_ZONE).get('month'),
  year: DateTime.now().setZone(TIME_ZONE).get('year'),
  setMonth: (month) => set({ month }),
  setYear: (year) => set({ year }),
}));
