import { useEffect, useState } from "react";
import Button from "../Button/index";
import UploadFile from "../Icons/UploadFile";
import Typography from "../Typography/index";
import { useModal } from "../../hooks/useModal";
import { toast } from "react-toastify";
import {
  useImportDoctorsMutation,
  useImportShiftsMutation,
} from "../../api/rosterApi";
import { MutatingDots } from "react-loader-spinner";
import Cross from "../Icons/Cross";
import Accordion from "../Accordion";
import { formatFileSize } from "../../utils/formatFileSize";

const host = import.meta.env.VITE_API_URL;
interface UploadModalProps {
  onSubmit?: (doctor: any) => void;
  type: "user" | "shift";
}

const UploadModal = (props: UploadModalProps) => {
  const { onSubmit, type } = props;

  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<{ error: string; rowNumber: number }[]>(
    []
  );
  const { closeModal } = useModal();

  const [
    importExcel,
    {
      isLoading: isImportExcelLoading,
      isSuccess: isImportExcelSuccess,
      isError: isImportExcelError,
      data: importExcelData,
      error: importExcelError,
    },
  ] = useImportDoctorsMutation();

  const [
    importShiftExcel,
    {
      isLoading: isImportShiftLoading,
      isSuccess: isImportShiftSuccess,
      isError: isImportShiftError,
      data: importShiftExcelData,
      error: importShiftExcelError,
    },
  ] = useImportShiftsMutation();

  useEffect(() => {
    if (isImportExcelSuccess) {
      toast.success(
        `${
          (importExcelData as unknown as any[])?.length
        } Doctors imported successfully, please export to generate passwords`
      );
      closeModal();
    }

    if (isImportExcelError) {
      toast.error(
        (importExcelError as any)?.data?.message ?? "Something went wrong"
      );
    }

    if (isImportShiftSuccess) {
      toast.success(
        `${
          (importShiftExcelData as unknown as any[])?.length
        } Shifts imported successfully`
      );
      closeModal();
    }

    if (isImportShiftError) {
      toast.error(
        (importShiftExcelError as any)?.data?.message ?? "Something went wrong"
      );
    }
  }, [
    isImportExcelSuccess,
    isImportExcelError,
    isImportShiftSuccess,
    isImportShiftError,
  ]);

  return (
    <div className="outer-shadow space-y-6 bg-white min-w-[30rem] w-screen max-w-xl px-8 py-6 mx-auto rounded-xl">
      <div className="flex items-center justify-between">
        <Typography tag="div" className="font-semibold text-xl text-black2">
          Upload File
        </Typography>
        <button
          className=" border-2 rounded-full border-primary p-1"
          onClick={closeModal}
        >
          <Cross className=" h-5 w-5 text-primary" />
        </button>
      </div>
      <div className="">
        <Button
          type="button"
          variant="bordered"
          className="w-min whitespace-nowrap px-4 ms-auto !h-8 !text-sm"
          disabled={isImportExcelLoading || isImportShiftLoading}
          onClick={async () => {
            window.open(
              `${host}/api/static/${type === "user" ? "UserUploadTest.xlsx" : "ShiftUploadTest.xlsx"}`,
              "_blank"
            );
          }}
        >
          Download Template
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-8 mb-8">
        <div className="col-span-2">
          {file ? (
            <>
              <div className="relative">
                <div className="font-semibold text-sm text-center text-black3 mb-6 border-2 h-16 rounded-lg bg-white outer-shadow flex items-center justify-center">
                  {file.name} - {formatFileSize(file.size)}
                </div>
              </div>
              {errors && Array.isArray(errors) && errors.length > 0 ? (
                <Accordion
                  className="p-3 "
                  headerClass=" uppercase text-red-700 !border-0 !font-bold"
                  contentClass=" border-t-2 border-red-500 pt-3 mt-3 !pr-0 !px-1"
                  content={
                    <>
                      {errors.map((error, index) => {
                        if (Array.isArray(error)) {
                          return (
                            <>
                              <Accordion
                                headerClass="bg-red-500 !text-white"
                                contentClass="pt-2"
                                content={
                                  <>
                                    {error.map((err) => {
                                      return (
                                        <>
                                          <Accordion
                                            headerClass="bg-red-500 !text-white"
                                            contentClass="pt-2"
                                            content={
                                              <div className="w-full text-gray-900 bg-white border border-red-600 rounded-lg ">
                                                {err.error
                                                  .split(".")
                                                  .map((e: string) => (
                                                    <button
                                                      type="button"
                                                      className="relative inline-flex items-center last:border-b-0 w-full px-4 py-2 text-red-600 text-sm font-medium border-b border-red-600 rounded-t-lg hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700"
                                                    >
                                                      {e}
                                                    </button>
                                                  ))}
                                              </div>
                                            }
                                            title={
                                              err.rowNumber === -1
                                                ? "Global"
                                                : `Row ${err.rowNumber}`
                                            }
                                          />
                                        </>
                                      );
                                    })}
                                  </>
                                }
                                title={`Worksheet ${index + 1}`}
                              />
                            </>
                          );
                        } else {
                          return (
                            <>
                              <Accordion
                                headerClass="bg-red-500 !text-white"
                                contentClass="pt-2"
                                content={
                                  <div className="w-full text-gray-900 bg-white border border-red-600 rounded-lg ">
                                    {error.error.split(".").map((er) => (
                                      <button
                                        type="button"
                                        className="relative inline-flex items-center last:border-b-0 w-full px-4 py-2 text-red-600 text-sm font-medium border-b border-red-600 rounded-t-lg hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700"
                                      >
                                        {er}
                                      </button>
                                    ))}
                                  </div>
                                }
                                title={
                                  error.rowNumber === -1
                                    ? "Global"
                                    : `Row ${error.rowNumber}`
                                }
                              />
                            </>
                          );
                        }
                      })}
                    </>
                  }
                  title={
                    <div className="flex items-center space-x-4">
                      <span>Errors</span>
                      <div className="text-white bg-red-600 animate-pulse text-xs h-6 w-6 flex items-center justify-center rounded-full">
                        {errors.length}
                      </div>
                    </div>
                  }
                />
              ) : (
                <div></div>
              )}
            </>
          ) : (
            <div className="relative outer-shadow p-4 rounded-lg mb-8">
              <input
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={async (e) => {
                  if (e.target.files) {
                    const selectedFile = e.target.files[0];
                    if (selectedFile.name.split(".")[1] !== "xlsx") {
                      toast.error(
                        "Invalid File Format - please select a xlsx format"
                      );
                    } else {
                      setFile(e.target.files[0]);
                    }
                  }
                }}
              />
              <div className="w-fit mx-auto relative ">
                <UploadFile />
              </div>
              <div className="font-semibold text-sm text-center text-black3 mb-2">
                Upload {type === "user" ? "User" : "Shift"} Details File From
                Excel.
              </div>
            </div>
          )}

          <div className="flex justify-center mt-0 gap-3 max-w-lg mx-auto">
            <Button
              onClick={closeModal}
              disabled={false}
              className="flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-10 w-"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              className="flex justify-center whitespace-nowrap px-4 !rounded-xl !h-10"
              disabled={!file}
              onClick={async () => {
                if (file) {
                  const form = new FormData();
                  form.append("excel", file);

                  const response =
                    type === "user"
                      ? await importExcel(form)
                      : await importShiftExcel(form);

                  if ("error" in response) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setErrors(response.error.data.message);
                  }
                  onSubmit?.({} as any);
                }
              }}
            >
              {isImportExcelLoading || isImportShiftLoading ? (
                <MutatingDots />
              ) : (
                `Add Bulk ${type === "user" ? "User" : "Shift"}`
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
