const removeKeys = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => {
      return !keys.includes(key as K);
    })
  ) as Omit<T, K>;
};

export default removeKeys;
