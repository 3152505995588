const Pause = ({ isActive }: { isActive: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25 5H20.5C19.9477 5 19.5 5.44772 19.5 6V26C19.5 26.5523 19.9477 27 20.5 27H25C25.5523 27 26 26.5523 26 26V6C26 5.44772 25.5523 5 25 5Z"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isActive ? "#67823A" : "#D1DBAF"}
      />
      <path
        d="M11.5 5H7C6.44772 5 6 5.44772 6 6V26C6 26.5523 6.44772 27 7 27H11.5C12.0523 27 12.5 26.5523 12.5 26V6C12.5 5.44772 12.0523 5 11.5 5Z"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isActive ? "#67823A" : "#D1DBAF"}
      />
    </svg>
  );
};

export default Pause;
