import { useMemo, useState } from "react";
import Calendar from "../Calender/Calendar";
import CalendarSwitcher from "../Calender/CalendarSwitcher";
import ProgressCard from "../ProgressCard";
import { useGetDoctorShiftsQuery } from "../../api/rosterApi";
import { MutatingDots } from "react-loader-spinner";
import useMonthCalendar from "../../hooks/useMonthCalendar";
import { formatTimeWSpace } from "../../utils/formatDate";
import { DateTime } from "luxon";
import { TIME_ZONE } from "../../constants";

const ShiftDetailsModal = ({ selectedUser }: { selectedUser: string }) => {
  const { month, year, calenderBack, calenderNext } = useMonthCalendar({});

  const [selectedDate, setSelectedDate] = useState<Date>();

  const {
    isLoading: isDoctorShiftsLoading,
    isFetching: isDoctorShiftsFetching,
    data: doctorShiftsData,
  } = useGetDoctorShiftsQuery({
    doctorId: selectedUser,
    month: month + 1,
    year,
  });

  const hasShifts: string[] = doctorShiftsData
    ?.filter((sd: any) => Object.keys(sd.schedule).length)
    .map((sd: { date: string }) => sd.date);

  const hasDayOff: string[] = doctorShiftsData
    ?.filter((sd: any) => sd.isDayOff)
    .map((sd: { date: string }) => sd.date);

  function getDateClass(date: Date) {
    const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);

    const isSelected = selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone(TIME_ZONE).equals(dateTime)
      : false;

    const isShift = hasShifts.find((date) =>
      dateTime.equals(DateTime.fromISO(date).setZone(TIME_ZONE))
    );

    const isLeave = hasDayOff.find((date) =>
      dateTime.equals(DateTime.fromISO(date).setZone(TIME_ZONE))
    );

    if (isSelected) {
      return "!bg-[#3e7c76] rounded-full border-2 !border-[#3e7c76] !text-[#DAF7E0]";
    }
    if (isShift) {
      return "!bg-[#D0FFFB] rounded-full border-2 border-[#3e7c76] !text-[#3e7c76]";
    }
    if (isLeave) {
      return "!bg-[#f4c94e] border-2 !text-[#fcedca]";
    }

    return "!bg-[#daf7e0] rounded-full  border-2 !border-[#3e7c76] !text-[#3e7c76]";
  }

  const selectedSchedule = useMemo(
    () =>
      selectedDate
        ? doctorShiftsData?.find((shift: { date: string }) => {
            return DateTime.fromJSDate(selectedDate)
              .setZone(TIME_ZONE)
              .equals(DateTime.fromISO(shift.date).setZone(TIME_ZONE));
          })
        : undefined,
    [doctorShiftsData, selectedDate]
  );

  return (
    <div className="bg-white z-50 flex flex-col gap-6  outer-shadow w-screen outer-shadow max-w-lg px-8 py-6 h-fit mx-auto rounded-xl">
      <div className="text-xl text-black1 font-bold uppercase text-center">
        Shift Details
      </div>
      <div className="">
        <div className="outer-shadow px-4 pt-2 pb-4 rounded-lg">
          <CalendarSwitcher
            month={month}
            year={year}
            onNext={() => calenderNext()}
            onPrev={() => calenderBack()}
            className="justify-between border-b-2 pb-4 border-green3"
            buttonClass="rounded-full w-10 h-10"
            iconClass="w-9 h-9"
          />
          <div className="grid grid-cols-7 gap-1 bg-green3 px-4 rounded-t-xl mt-4">
            {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((weekDay) => (
              <div
                className="col-span-1 h-12 w-12  flex items-center justify-center font-bold"
                key={weekDay}
              >
                {weekDay}
              </div>
            ))}
          </div>
          {isDoctorShiftsLoading || isDoctorShiftsFetching ? (
            <div className="flex justify-center items-center h-[350px]">
              <MutatingDots />
            </div>
          ) : (
            <Calendar
              month={month}
              year={year}
              className="!gap-2 bg-primary rounded-b-2xl"
              onDayRender={({ day }) => {
                return (
                  <div
                    key={day.getTime()}
                    className={`rounded-full cursor-pointer w-11 h-11 flex items-center justify-center text-black3 font-bold text-base ${getDateClass(
                      day
                    )}`}
                    onClick={() => {
                      setSelectedDate(day);
                    }}
                  >
                    {day.getDate()}
                  </div>
                );
              }}
            />
          )}
        </div>
        <br />

        {selectedSchedule && Object.keys(selectedSchedule.schedule).length ? (
          <ProgressCard
            slotFilled={selectedSchedule.schedule.shift.doctorsFilled}
            totalNumberSlot={
              selectedSchedule.schedule.shift.totalDoctorsRequired
            }
            loacation={selectedSchedule.schedule.shift.location.shortLabel}
            shiftTiming={`${formatTimeWSpace(
              selectedSchedule.schedule.shift.time.from
            )} - ${formatTimeWSpace(selectedSchedule.schedule.shift.time.to)}`}
          />
        ) : null}
        {selectedSchedule &&
        Object.keys(selectedSchedule.schedule).length == 0 ? (
          <div className="relative shadow-xl">
            <img
              src="/images/BackgroundOffCard.png"
              className="w-full h-36"
              alt="background-image"
            />
            <div className="absolute bg-black flex justify-center h-full w-full top-0 bg-opacity-25 rounded-xl">
              <div className="text-white m-auto w-fit font-bold text-5xl uppercase">
                Day Off
              </div>
              {!selectedSchedule.isDayOff && (
                <div className="text-white m-auto font-bold uppercase">
                  No shift assigned
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-center mt-auto gap-8 max-w-md mx-auto w-full"></div>
    </div>
  );
};

export default ShiftDetailsModal;
