import { useState } from "react";
import ChatBubble from "../Icons/ChatBubble";
import RoundedCancel from "../Icons/RoundedCancel";
import RoundedTick from "../Icons/RoundedTick";
import { AnimatePresence, motion } from "framer-motion";
import { formatTime } from "../../utils/formatDate";
import ClockTwo from "../Icons/ClockTwo";
import useDebounce from "../../hooks/useDebounce";

type UserRequestCardProps = {
  reason?: string;
  customTime?: { from: string; to: string };
  name: string;
  status: "completed" | "rejected" | "pending";
  rejectHandler: () => Promise<void>;
  acceptHandler: () => Promise<void>;
  isLoading: boolean;
  seniority: number;
  locationLabel?: string;
  isDisabled?: boolean;
  row: { number: number; numberRows: number };
};

const bgSeniority = {
  "3": "bg-[#DF9C28]",
  "2": "bg-[#84ACE2]",
  "1": "bg-[#E57041]",
};

const UserRequestCard = ({
  customTime,
  reason,
  name,
  status,
  acceptHandler,
  rejectHandler,
  isLoading,
  seniority,
  locationLabel,
  isDisabled = false,
  row,
}: UserRequestCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedType, setExpandedType] = useState<"reason" | "customTime">(
    "reason"
  );
  const { deboucedCallback: debouncedSetIsExpTrue, isBlocked } = useDebounce(
    () => {
      setIsExpanded(true);
    },
    800
  );

  return (
    <div className="relative">
      {isExpanded && (
        <div
          className={`absolute -top-[90px] bg-gray6 w-full h-[130px]`}
          style={{ zIndex: (row.numberRows - row.number) * 2 }}
        ></div>
      )}
      <div
        className={`${
          isExpanded ? "rounded-t-xl shadow-bottomGrey" : "rounded-xl"
        } bg-white pl-4 pr-1 flex justify-between relative ${
          isLoading ? "animate-pulseFast" : ""
        }`}
        style={{
          zIndex: row.numberRows * 2,
        }}
      >
        <div
          className={`flex my-3 items-center ${
            !isDisabled ? "cursor-pointer" : ""
          }`}
          onClick={() => {
            if (!isDisabled) {
              if (!isExpanded) {
                debouncedSetIsExpTrue();
              } else if (isExpanded && !isBlocked) {
                setIsExpanded(false);
              }
            }
          }}
        >
          <div
            className={`relative -left-6 rounded-l-lg ${
              bgSeniority[seniority.toString() as "1" | "2" | "3"]
            } w-2 h-8`}
          ></div>
          <div className="font-semibold leading-0">{name}</div>
          {locationLabel && (
            <div className="bg-secondary text-white p-1 rounded text-xs font-bold ml-5">
              {locationLabel}
            </div>
          )}
        </div>
        {!isExpanded && (
          <div
            className={
              "flex flex-grow flex-row-reverse group overflow-hidden gap-1"
            }
          >
            {!isDisabled && (
              <>
                <div className="mt-1.5 justify-between relative hidden group-hover:flex flex-col">
                  <div
                    onClick={async () => {
                      if (status !== "rejected") {
                        await rejectHandler();
                      }
                    }}
                  >
                    <RoundedCancel active={status === "rejected"} />
                  </div>
                  <div
                    className="relative left-[0.9px] bottom-[0.5px]"
                    onClick={async () => {
                      if (status !== "completed") {
                        await acceptHandler();
                      }
                    }}
                  >
                    <RoundedTick active={status === "completed"} />
                  </div>
                </div>
              </>
            )}
            {(status === "completed" || status === "rejected") && (
              <div
                className={`${
                  !isDisabled ? "group-hover:hidden" : ""
                } my-1 ml-1`}
              >
                {status === "completed" && <RoundedTick active={true} />}
                {status === "rejected" && <RoundedCancel active={true} />}
              </div>
            )}
            {reason && (
              <div className={`my-[7px]`}>
                <ChatBubble />
              </div>
            )}
            {customTime && (
              <div className={`my-[7px]`}>
                <ClockTwo />
              </div>
            )}
          </div>
        )}
        {isExpanded && (
          <div className="flex-grow flex flex-row-reverse">
            <div className="justify-center w-[50px] shadow-leftGrey flex items-center h-full">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (!(customTime && reason)) {
                    setIsExpanded(false);
                  } else if (expandedType === "customTime") {
                    setExpandedType("reason");
                  } else if (expandedType === "reason") {
                    setExpandedType("customTime");
                  }
                }}
              >
                {!(customTime && reason) && (
                  <div className="relative left-0.5">
                    <RoundedCancel active={false} />
                  </div>
                )}
                {customTime && reason && expandedType === "customTime" && (
                  <ChatBubble />
                )}
                {customTime && reason && expandedType === "reason" && (
                  <ClockTwo />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key="user-request"
            initial={{
              y: !reason ? -100 : -200,
            }}
            animate={{ y: 0 }}
            exit={{ y: 0 }}
            transition={{ type: "interia", duration: reason ? 0.8 : 0.4 }}
            style={{ zIndex: (row.numberRows - row.number) * 2 - 1 }}
            className={`relative rounded-b-xl flex flex-col gap-1 ${
              reason ? "p-5" : "pb-5 pt-2 px-5"
            } bg-white`}
          >
            {customTime && (expandedType === "customTime" || !reason) && (
              <div className="bg-gray6 rounded-md p-3 font-medium">
                <div className="bg-white flex justify-between rounded-md py-2 px-4 items-center relative">
                  <div className="absolute top-0 right-0 scale-125">
                    <ClockTwo />
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-[0.5rem]">START TIME</div>
                    <div className="text-blue1 font-bold text-lg">
                      {formatTime(customTime.from)}
                    </div>
                  </div>{" "}
                  <div className="flex flex-col justify-center">
                    <div className="text-[0.5rem]">END TIME</div>
                    <div className="text-blue1 font-bold text-lg">
                      {formatTime(customTime.to)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {reason && (expandedType === "reason" || !customTime) && (
              <div className="bg-gray6 rounded-md p-3 font-medium relative">
                <div className="absolute top-0 right-0 scale-125">
                  <ChatBubble />
                </div>
                {reason}
              </div>
            )}
            <div className="flex justify-between mt-4">
              <button
                className={`${
                  status === "rejected" ? "bg-gray2" : "bg-red2"
                } text-white px-4 py-1 rounded-lg font-medium text-sm`}
                onClick={async () => {
                  await rejectHandler();
                }}
                disabled={status === "rejected"}
              >
                reject
              </button>
              <button
                className={`${
                  status === "completed" ? "bg-gray2" : "bg-green5"
                } text-white px-4 py-1 rounded-lg font-medium text-sm`}
                onClick={async () => {
                  await acceptHandler();
                }}
                disabled={status === "completed"}
              >
                accept
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserRequestCard;
