import { MutableRefObject, useEffect, useRef } from "react";

type EventHandler = (e: Event, i?: number) => void;

const useRightClick = (
  ref:
    | MutableRefObject<HTMLElement | null>
    | MutableRefObject<(HTMLDivElement | null)[]>,
  rightClickHandler: (i?: number) => void
) => {
  const handlerRef = useRef<EventHandler>((e: Event, i?: number) => {
    e.preventDefault();
    rightClickHandler(i);
  });

  handlerRef.current = (e: Event, i?: number) => {
    e.preventDefault();
    rightClickHandler(i);
  };

  useEffect(() => {
    const eventHandlers: EventHandler[] = [];

    if (Array.isArray(ref.current)) {
      ref.current?.forEach((divElement, index) => {
        eventHandlers.push((e: Event) => {
          handlerRef.current(e, index);
        });
        divElement?.addEventListener("contextmenu", eventHandlers[index]);
      });
    } else {
      eventHandlers.push((e: Event) => {
        handlerRef.current(e);
      });
      ref.current?.addEventListener("contextmenu", eventHandlers[0]);
    }

    const cleanUpCopy = ref.current;

    return () => {
      if (Array.isArray(cleanUpCopy)) {
        cleanUpCopy.forEach((divElement, index) =>
          divElement?.removeEventListener("contextmenu", eventHandlers[index])
        );
      } else {
        cleanUpCopy?.removeEventListener("contextmenu", eventHandlers[0]);
      }
    };
  }, [ref]);
};

export default useRightClick;
