import { FC } from "react";

const ClockThree = ({ isActive }: { isActive: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M8.645 9.555L9.555 8.645L7.15 6.24V3.25H5.85V6.76L8.645 9.555ZM6.5 13C5.60083 13 4.75583 12.8294 3.965 12.4881C3.17417 12.1469 2.48625 11.6837 1.90125 11.0987C1.31625 10.5138 0.853125 9.82583 0.511875 9.035C0.170625 8.24417 0 7.39917 0 6.5C0 5.60083 0.170625 4.75583 0.511875 3.965C0.853125 3.17417 1.31625 2.48625 1.90125 1.90125C2.48625 1.31625 3.17417 0.853125 3.965 0.511875C4.75583 0.170625 5.60083 0 6.5 0C7.39917 0 8.24417 0.170625 9.035 0.511875C9.82583 0.853125 10.5138 1.31625 11.0987 1.90125C11.6837 2.48625 12.1469 3.17417 12.4881 3.965C12.8294 4.75583 13 5.60083 13 6.5C13 7.39917 12.8294 8.24417 12.4881 9.035C12.1469 9.82583 11.6837 10.5138 11.0987 11.0987C10.5138 11.6837 9.82583 12.1469 9.035 12.4881C8.24417 12.8294 7.39917 13 6.5 13ZM6.5 11.7C7.94083 11.7 9.16771 11.1935 10.1806 10.1806C11.1935 9.16771 11.7 7.94083 11.7 6.5C11.7 5.05917 11.1935 3.83229 10.1806 2.81937C9.16771 1.80646 7.94083 1.3 6.5 1.3C5.05917 1.3 3.83229 1.80646 2.81937 2.81937C1.80646 3.83229 1.3 5.05917 1.3 6.5C1.3 7.94083 1.80646 9.16771 2.81937 10.1806C3.83229 11.1935 5.05917 11.7 6.5 11.7Z"
        fill={isActive ? "white" : "#61763A"}
      />
    </svg>
  );
};

export default ClockThree;
