import { useEffect } from "react";
import Switch from "rc-switch";
import { InfinitySpin } from "react-loader-spinner";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import "../../assets/rc-switch.css";

import OuterWindow from "./OuterWindow";
import ShiftCard from "./ShiftCard";
import RequestPhaseWindow from "./RequestPhaseWindow";

import {
  sortShifts,
  useGetShiftsSingle,
} from "../../hooks/estimation/useGetShifts";
import {
  useAutoAssignmentDateMutation,
  useGetDoctorStatsQuery,
} from "../../api/rosterApi";
import {
  useGetDayOffRequestsQuery,
  useGetShiftRequestsQuery,
} from "../../api/requestsApi";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useLocation } from "../../store/location.store";
import { formatDateAPI, formatDisplayDate } from "../../utils/formatDate";
import { Shift } from "../../interface/shift";
import { useGetCalendarQuery, useGetDayOffsQuery } from "../../api/locationApi";
import Grid from "../Icons/Grid";
import { useSeniority } from "../../store/seniority.state";
import { sortRequests } from "../../utils/sortRequests";
import { TIME_ZONE } from "../../constants";
import useReloadFirebase from "../../hooks/useReloadFirebase";
import { checkContested, shiftRequestFilter } from "../../utils/shiftRequests";
import useHandleOnScheduleChange from "../../hooks/estimation/handleOnScheduleAdd";
import { getDateRange } from "../../utils/date";
import useHandleOnDayOffRequestProcess from "../../hooks/estimation/handleOnDayOffRequestProcess";
import handleResponse from "../../utils/handleResponse";

type PrimaryMonitorProps = {
  activeDate: Date;
  className?: string;
};

const getDisplayType = (
  shiftData: any,
  isShiftsLoading: boolean,
  isShiftsFetching: boolean,
  isOpenRequest: boolean,
  isDayOffLoading: boolean,
  isShiftRequestsLoading: boolean
): "shifts" | "no-shifts" | "requests-phase" | "loading" => {
  if (
    isShiftsLoading ||
    (!shiftData && isShiftsFetching) ||
    isDayOffLoading ||
    isShiftRequestsLoading
  ) {
    return "loading";
  }
  if (isOpenRequest) {
    return "requests-phase";
  }
  if (shiftData && shiftData.length > 0) {
    return "shifts";
  }
  return "no-shifts";
};

const PrimaryMonitor = ({ activeDate, className }: PrimaryMonitorProps) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const {
    setRequestTab,
    isOpenRequest,
    setIsOpenRequest,
    activeTab,
    autoAssign,
    doctorFilterSeniority,
  } = useEstimationContext();

  const {
    data: shiftData,
    isLoading: isShiftsLoading,
    isFetching: isShiftsFetching,
  } = useGetShiftsSingle({ activeDate });

  // move this into another shift request hook
  const {
    data: dayOffData,
    isLoading: isDayOffLoading,
    isFetching: isDayOffFetching,
    refetch: refetchDayOffs,
  } = useGetDayOffsQuery({ date: formatDateAPI(activeDate) });

  // move this into another shift request hook
  const {
    data: shiftRequests,
    isLoading: isShiftRequestsLoading,
    isFetching: isShiftRequestsFetching,
    refetch: refetchShiftRequests,
  } = useGetShiftRequestsQuery(
    {
      locationId: activeLocationId,
      date: formatDateAPI(activeDate),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId }
  );

  useReloadFirebase({
    childKey: "requests",
    callback: () => {
      try {
        refetchShiftRequests();
      } catch (error) {
        console.error(error);
      }
      try {
        refetchDayOffRequests();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const activeDateTime = DateTime.fromJSDate(activeDate).setZone(TIME_ZONE);

  const { refetch: refetchStats } = useGetDoctorStatsQuery({
    seniority: activeSeniorityId,
    month: activeDateTime.get("month"),
    year: activeDateTime.get("year"),
  });

  // move this into another shift request hook
  const {
    data: dayOffRequests,
    isLoading: isDayOffRequestsLoading,
    isFetching: isDayOffRequestsFetching,
    refetch: refetchDayOffRequests,
  } = useGetDayOffRequestsQuery({
    date: formatDateAPI(activeDate),
  });

  const { data: calendarData } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: activeDateTime.get("month"),
      year: activeDateTime.get("year"),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId }
  );

  const bodyNodeType = getDisplayType(
    shiftData,
    isShiftsLoading,
    isShiftsFetching,
    isOpenRequest,
    isDayOffLoading,
    isShiftRequestsLoading
  );

  const onShiftRequestProcess = useHandleOnScheduleChange({
    ...getDateRange(activeDate),
    shouldRefetchShiftRequests: false,
  });

  const onScheduleAdd = useHandleOnScheduleChange({
    ...getDateRange(activeDate),
  });

  const onDayOffRequestProcess = useHandleOnDayOffRequestProcess({
    ...getDateRange(activeDate),
  });

  const [assignRandomnDate, { isLoading: isAssignRandomDateLoading }] =
    useAutoAssignmentDateMutation();

  const handleIsRequest = () => {
    setIsOpenRequest(!isOpenRequest);
    setRequestTab([...shiftData].sort(sortShifts)?.[0]?._id ?? "dayOff");
  };

  return (
    <OuterWindow
      className={`w-full h-full ${className}`}
      title={`${formatDisplayDate(activeDate)} ${
        isOpenRequest ? "(Requests)" : ""
      }`}
      titleClassName="text-white transition duration-500 "
      headerChildNode={
        <>
          <button
            disabled={isAssignRandomDateLoading}
            className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit left-6 p-0.5`}
            onClick={async () => {
              if (activeSeniorityId) {
                const response = await assignRandomnDate({
                  seniority: activeSeniorityId,
                  location: activeLocationId,
                  date: formatDateAPI(activeDate),
                  ...(!autoAssign && activeTab !== "suggested"
                    ? activeTab === "seniority"
                      ? { forceSeniority: doctorFilterSeniority }
                      : { forceSeniority: 4 }
                    : {}),
                });
                handleResponse(
                  response,
                  "Date shift assignment complete.",
                  async () => {
                    await onScheduleAdd();
                    refetchDayOffRequests();
                  }
                );
              }
            }}
          >
            <Grid
              className={isAssignRandomDateLoading ? "animate-spin" : ""}
              fill={"white"}
            />
          </button>
          <div
            className={`absolute top-0 bottom-0 right-6 h-fit my-auto ${
              isOpenRequest
                ? "custom-rc-switch-checked"
                : "custom-rc-switch-unchecked"
            }`}
          >
            <Switch onClick={() => handleIsRequest()} checked={isOpenRequest} />
          </div>
        </>
      }
      headerClassName={`${
        !isOpenRequest ? "bg-secondary" : "bg-teal3"
      } relative transition duration-500`}
      bodyClass={`${
        !isOpenRequest ? "" : "grid grid-cols-1 gap-6"
      } transition duration-500 flex-grow overflow-auto`}
      bodyChildNode={{
        shifts: () => (
          <div className="grid-cols-2 grid gap-4">
            {[...shiftData].sort(sortShifts).map((shift: Shift) => {
              const currentShiftRequests =
                shiftRequests?.filter(
                  shiftRequestFilter(shift._id, "pending")
                ) ?? [];
              const isContested = checkContested(currentShiftRequests);
              return (
                <ShiftCard
                  isFetching={isShiftsFetching}
                  date={activeDate}
                  type={shift.type}
                  key={`${shift._id}`}
                  shiftId={shift._id}
                  time={shift.time}
                  slots={shift.slots}
                  windowType={"primary"}
                  requestsStatus={
                    shiftRequests?.find(shiftRequestFilter(shift._id, "all"))
                      ? isContested
                        ? "contested"
                        : "requests"
                      : "no-requests"
                  }
                  requestHandler={() => {
                    setIsOpenRequest(true);
                    setRequestTab(shift._id);
                  }}
                />
              );
            })}
          </div>
        ),
        "no-shifts": () => (
          <motion.div
            key="noDataTransition"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 210 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.8 }}
            className="font-medium flex justify-center items-center h-[210px]"
          >
            No Shifts Added
          </motion.div>
        ),
        "requests-phase": () => (
          <RequestPhaseWindow
            date={activeDate}
            shifts={shiftData ? [...shiftData].sort(sortShifts) : undefined}
            shiftRequests={
              shiftRequests ? [...shiftRequests].sort(sortRequests) : undefined
            }
            dayOffRequests={
              dayOffRequests
                ? [...dayOffRequests].sort(sortRequests)
                : undefined
            }
            dayOff={dayOffData}
            isLoading={
              isShiftRequestsLoading ||
              isDayOffRequestsLoading ||
              isDayOffLoading
            }
            isFetching={
              isShiftRequestsFetching ||
              isDayOffRequestsFetching ||
              isDayOffFetching
            }
            refetchShiftRequests={async () => {
              await refetchShiftRequests();
            }}
            refetchDayOffRequests={async () => {
              await refetchDayOffRequests();
            }}
            refetchDayOffs={async () => {
              await refetchDayOffs();
            }}
            refetchStats={async () => {
              await refetchStats();
            }}
            onShiftRequestProcess={async () => {
              await onShiftRequestProcess();
            }}
            onDayOffRequestProcess={onDayOffRequestProcess}
            deadline={calendarData?.deadline}
          />
        ),
        loading: () => (
          <div className="h-full w-full flex justify-center items-center">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ),
      }[bodyNodeType]()}
    />
  );
};

export default PrimaryMonitor;
