import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

interface AccordionProps {
  title: React.ReactNode;
  content: React.ReactNode;
  headerClass?: string;
  contentClass?: string;
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  className,
  title,
  content,
  headerClass,
  contentClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`border-2 rounded-md border-red-500 mb-4 mr-3 ${className}`}
    >
      <div
        className={`flex justify-between items-center px-4 py-1.5  cursor-pointer ${headerClass}`}
        onClick={toggleAccordion}
      >
        <h2 className="text-base font-medium ">{title}</h2>
        <span
          className={`transform ${
            isOpen ? "rotate-180" : "rotate-0"
          } transition-transform`}
        >
          <BsChevronDown className="text-inherit h-4 w-4" />
        </span>
      </div>
      {isOpen && (
        <div className={`px-4 pb-4 max-h-60 overflow-auto  ${contentClass}`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
