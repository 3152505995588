import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";

import NavTab, { getSenioritiesNavTabs } from "../NavTabs";
import Table from "../Icons/Table";

import { useSeniority } from "@/store/seniority.state";
import { useEstimationContext } from "@/hooks/context/useEstimationContext";
import { useRunOnceExt } from "@/hooks/runOnce";

export const TopBar = ({
  globalLoading,
  setEstimationParams,
}: {
  globalLoading: boolean;
  setEstimationParams: (input: {
    locationId?: string;
    seniority?: number;
  }) => void;
}) => {
  const navigate = useNavigate();

  const { disabledSeniorities, activeId, setActiveId } = useSeniority();
  const { setDoctorFilterSeniority } = useEstimationContext();
  const { dateHash } = useParams<{ dateHash: string }>();

  const { resetStates } = useEstimationContext();
  const [searchParams] = useSearchParams();

  useRunOnceExt(() => {
    if (searchParams.get("seniority")) {
      setActiveId(Number(searchParams.get("seniority")));
      setDoctorFilterSeniority(Number(searchParams.get("seniority")));
    } else {
      setEstimationParams({
        seniority: activeId,
      });
    }
  });

  return (
    <div className="w-full bg-white items-center flex justify-between px-10">
      <div
        className="flex items-center bg-gray2 border-gray cursor-pointer rounded h-[25px] w-[25px]"
        onClick={() => {
          navigate(
            `/dashboard?locationId=${searchParams.get(
              "locationId"
            )}&seniority=${searchParams.get("seniority")}&dateHash=${
              dateHash ?? ""
            }`
          );
        }}
      >
        <IoChevronBackOutline size={20} className="text-black mx-auto" />
      </div>
      <NavTab
        tabs={getSenioritiesNavTabs(disabledSeniorities)}
        tabType="neumorphism"
        activeId={activeId ?? 1}
        onChange={(id) => {
          setEstimationParams({
            seniority: id,
          });
          resetStates(id);
          setActiveId(id);
        }}
      />

      <div className=" text-secondary flex text-base font-medium items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/table");
          }}
        >
          <Table isActive={false} />
        </div>
        {globalLoading ? (
          <>
            <InfinitySpin width="100" color="#67823A" />
            Syncing...
          </>
        ) : (
          <>Synced</>
        )}
      </div>
    </div>
  );
};
