import { FC, useEffect, useState } from "react";
import GuacLoader from "../components/GuacLoader";
import { Outlet, useNavigate } from "react-router-dom";
import { axiosApi } from "../api/axios";
import { useProfile } from "../store/profile.state";

const Auth: FC<{ role: string }> = ({ role }) => {
  const [isAuth, setAuth] = useState(false);
  const navigate = useNavigate();
  const { setUsername, setRole, setProfilePhoto, setUserId } = useProfile();
  useEffect(() => {
    const me = async (token: string) => {
      try {
        const res = await axiosApi({
          url: "/api/auth/me",
          method: "GET",
          headers: {
            Authorization: token,
          },
        });
        localStorage.setItem("guacProfile", JSON.stringify(res.data));
        setUsername(res.data.name);
        setRole(res.data.role.label);
        setProfilePhoto(res.data.profilePicture?.url ?? "");
        setUserId(res.data._id);
        return res.data.role.label;
      } catch (err) {
        localStorage.removeItem("token");
        localStorage.removeItem("guacProfile");
        setAuth(false);
        navigate("/");
      }
    };

    const token = localStorage.getItem("token");
    if (token) {
      me(token)
        .then((_role) => {
          if (_role !== role) {
            setAuth(false);
            navigate("/");
          }
          setAuth(true);
        })
        .catch(() => {
          setAuth(false);
          navigate("/");
        });
    } else {
      setAuth(false);
      navigate("/");
    }
  }, []);

  if (isAuth) {
    return <Outlet />;
  }

  return <GuacLoader />;
};

export default Auth;
