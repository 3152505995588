import { useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

import ShiftPointsBlock from "./ShiftPointsBlock";
import SideBar from "../SideBar";
import NavTab, { getSenioritiesNavTabs } from "../NavTabs";
import { useGetShiftPointsQuery } from "../../api/preferencesApi";
import { ShiftType } from "../../interface/shift";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import { useRunOnChangeExt } from "../../hooks/runOnChange";
import { useGetLocationsQuery } from "@/api/locationApi";

const SHIFT_TYPES: { key: ShiftType; label: string }[] = [
  {
    key: "normal",
    label: "Shift",
  },
  {
    key: "onCall",
    label: "On Call",
  },
  {
    key: "standBy",
    label: "Stand By",
  },
];

const CustomPointsItem = () => {
  const { disabledSeniorities, activeId, setActiveId } = useSeniority();
  const { activeId: activeLocationId, setActiveLocation } = useLocation();

  const { data: locationsData } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const [activeShiftType, setActiveShiftType] = useState<ShiftType | undefined>(
    undefined
  );

  const [triggerReset, setTriggerReset] = useState<boolean>(false);

  const resetState = () => {
    setActiveShiftType(undefined);
    setTriggerReset(!triggerReset);
  };

  const {
    data: pointsData,
    isLoading: isPointsDataLoading,
    isFetching: isPointsDataFetching,
    refetch: refetchPointsData,
  } = useGetShiftPointsQuery(
    {
      seniority: activeId,
      locationId: activeLocationId,
    },
    { skip: !activeLocationId }
  );

  useRunOnChangeExt(locationsData, () => {
    if (locationsData?.locations.length) {
      setActiveLocation(locationsData.locations[0]);
    }
  });

  return (
    <div className="w-full h-full flex">
      <SideBar
        type={"shiftPoints"}
        isCalendarLoading={false}
        setParams={{
          dashboard: undefined,
          estimation: undefined,
          shiftPoints: undefined,
        }}
      />
      <div className="flex-grow flex flex-col p-4">
        <div className="flex px-8 mb-2">
          <div className="text-4xl font-extrabold w-[25%]">Guaconomy</div>
          <div className="w-[50%] mb-2">
            <NavTab
              tabs={getSenioritiesNavTabs(disabledSeniorities)}
              className={"bg-white"}
              tabType="neumorphism"
              activeId={activeId ?? 1}
              onChange={(id) => {
                setActiveId(id);
                resetState();
              }}
            />
          </div>
        </div>
        <div className="flex gap-2 px-8 flex-grow w-[90%]">
          {isPointsDataLoading || !pointsData ? (
            <InfinitySpin width="200px" color="#67823A" />
          ) : (
            SHIFT_TYPES.map((st) => (
              <ShiftPointsBlock
                isLoading={isPointsDataFetching}
                refetch={() => {
                  refetchPointsData();
                }}
                key={st.key}
                shiftLabel={st.label}
                isActive={st.key === activeShiftType}
                setIsActive={() => {
                  setActiveShiftType(
                    activeShiftType === st.key ? undefined : st.key
                  );
                  setTriggerReset(!triggerReset);
                }}
                triggerReset={triggerReset}
                pointsData={pointsData.filter(
                  (pD: { shiftType: ShiftType }) => pD.shiftType === st.key
                )}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomPointsItem;
