import { create } from "zustand";
import { ApiState, getInitialState } from "../store";

type LoginApi = ApiState<
  { email: string; password: string; allowedRoles: number[] },
  { token: string; role: string; firebaseToken: string },
  any
>;

const useLogin = create<LoginApi>((set) => {
  return getInitialState<
    { email: string; password: string; allowedRoles: number[] },
    { token: string; role: string; firebaseToken: string },
    any
  >(
    {
      url: "/api/auth/login",
      method: "POST",
    },
    set,
  );
});

type ResetPassword = ApiState<{ email: string }, any, any>;

const useResetPassword = create<ResetPassword>((set) => {
  return getInitialState<{ email: string }, any, any>(
    {
      url: "/api/auth/reset-password",
      method: "POST",
    },
    set,
  );
});

type VerifyOtp = ApiState<
  { email: string; otp: string },
  { token: string; message: string },
  any
>;

const useVerifyOtp = create<VerifyOtp>((set) => {
  return getInitialState<
    { email: string; otp: string },
    { token: string; message: string },
    any
  >(
    {
      url: "/api/auth/verify-otp",

      method: "POST",
    },
    set,
  );
});

type SetNewPassword = ApiState<{ newPassword: string }, any, any>;

const useSetNewPassword = create<SetNewPassword>((set) => {
  return getInitialState<{ newPassword: string }, any, any>(
    {
      url: "/api/auth/new-password",
      method: "POST",
    },
    set,
  );
});

type OnBoard = ApiState<FormData, any, any>;

const useOnBoard = create<OnBoard>((set) => {
  return getInitialState<FormData, any, any>(
    {
      url: "/api/onboard",
      method: "POST",
    },
    set,
  );
});

type OnboardPassword = ApiState<{ password: string }, any, any>;

const useOnboardPassword = create<OnboardPassword>((set) => {
  return getInitialState<{ password: string }, any, any>(
    {
      url: "/api/onboard/finish",
      method: "POST",
    },
    set,
  );
});

type UploadAvatar = ApiState<FormData, any, any>;

const useUploadAvatar = create<UploadAvatar>((set) => {
  return getInitialState<FormData, any, any>(
    {
      url: "/api/profilepicture",
      method: "PUT",
    },
    set,
  );
});

const auth = {
  useLogin,
  useResetPassword,
  useVerifyOtp,
  useSetNewPassword,
  useOnBoard,
  useOnboardPassword,
  useUploadAvatar,

  // useMe,
  // useRegister,
};

export default auth;
