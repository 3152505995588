import { formatTimeWSpace } from "../../utils/formatDate";
import { ShiftGroup } from "./DayView";
import DoctorCard from "../Cards/SmallDoctorCard";
import { GROUP_COLORS } from "@/constants";

const ShiftBlock = ({
  shiftGroup,
  columnType,
}: {
  shiftGroup: ShiftGroup;
  columnType: "double" | "single";
}) => {
  return (
    <div
      className={`${
        GROUP_COLORS[shiftGroup.key].bgColor
      } rounded-xl flex mb-3 p-0.5`}
      key={shiftGroup.key}
    >
      <div
        className={`vertical-text -scale-100 ${
          shiftGroup.timeGroups ? "w-[45px] px-[6px]" : "w-[90px] px-[27px]"
        } font-semibold text-2xl text-white py-3 text-center`}
      >
        {shiftGroup.label}
      </div>
      {shiftGroup.timeGroups && (
        <div className="flex-grow">
          {shiftGroup.timeGroups.map((timeGroup, index) => {
            const timeArray = formatTimeWSpace(timeGroup.time.from).split(" ");
            return (
              <div
                className={`flex ${
                  index < shiftGroup.timeGroups!.length - 1 ? "mb-[2px]" : ""
                }${shiftGroup.timeGroups!.length === 1 ? "h-full" : ""} `}
                key={index}
              >
                <div
                  className={`bg-white rounded-l-xl text-center p-2 opacity-70 w-[45px] flex flex-col justify-center`}
                >
                  {timeArray[0].length <= 2 && (
                    <div
                      className={`${
                        GROUP_COLORS[shiftGroup.key].textColor
                      } font-black text-3xl`}
                    >
                      {timeArray[0]}
                    </div>
                  )}
                  {timeArray[0].length > 2 && (
                    <>
                      <div
                        className={`${
                          GROUP_COLORS[shiftGroup.key].textColor
                        } font-black text-[1.6rem] leading-8`}
                      >
                        {`${timeArray[0].split(":")[0]}:`}
                      </div>
                      <div
                        className={`${
                          GROUP_COLORS[shiftGroup.key].textColor
                        } font-black text-[1.6rem] leading-8`}
                      >
                        {timeArray[0].split(":")[1]}
                      </div>
                    </>
                  )}
                  <div
                    className={`${
                      GROUP_COLORS[shiftGroup.key].textColor
                    } font-black`}
                  >
                    {timeArray[1]}
                  </div>
                </div>
                <div className={`flex flex-grow bg-white p-2 rounded-r-xl`}>
                  <div
                    className={`w-full grid gap-2 ${
                      columnType === "double" ? "grid-cols-2" : "grid-cols-1"
                    } h-fit`}
                  >
                    {timeGroup.shifts
                      .map((shift) => {
                        return shift.slots
                          .filter((slot) => Boolean(slot.schedule))
                          .map((slot) => (
                            <DoctorCard
                              key={slot.schedule.doctor._id}
                              name={slot.schedule.doctor.user.name}
                              nickName={slot.schedule.doctor.user.nickName}
                              location={shift.location.shortLabel}
                              seniority={slot.schedule.doctor.seniority.id}
                            />
                          ));
                      })
                      .flat()}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {shiftGroup.doctors && (
        <div className="flex-grow p-3 bg-white rounded-r-xl">
          <div
            className={`grid ${
              columnType === "double" ? "grid-cols-2" : "grid-cols-1"
            } gap-2`}
          >
            {shiftGroup.doctors.map((doctor, index) => {
              return (
                <DoctorCard
                  key={index}
                  name={doctor.doctor.user.name}
                  location={doctor.location}
                  seniority={doctor.doctor.seniority?.id ?? doctor.doctor.seniority}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftBlock;
