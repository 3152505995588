import {
  TagDescription,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";
import { rosterApi } from "./rosterApi";

const invalidateTagsForDoctors = async (
  arg: any,
  api: {
    queryFulfilled: any;
    dispatch: (arg0: {
      payload: TagDescription<"DoctorSuggestions" | "DoctorSeniority">[];
      type: string;
    }) => void;
  }
) => {
  try {
    await api.queryFulfilled;
    api.dispatch(
      rosterApi.util.invalidateTags(["DoctorSuggestions", "DoctorSeniority"])
    );
  } catch {
    /* empty */
  }
};

const API_URL = import.meta.env.VITE_API_URL;

export const doctorApi = createApi({
  reducerPath: "doctorApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  tagTypes: ["Doctor"],
  endpoints: (builder) => ({
    getDoctors: builder.query<any, any>({
      query: ({ seniority, page, max, q, isIdle }) => ({
        url: `/doctor/fetch?seniority=${seniority}${
          page ? `&page=${page}` : ""
        }${max ? `&max=${max}` : ""}${q ? `&q=${q}` : ""}${
          isIdle !== undefined ? `&isIdle=${isIdle}` : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Doctor"],
    }),
    updateDoctor: builder.mutation<any, any>({
      query: ({ doctorId, ...data }) => ({
        url: `/doctor/${doctorId}`,
        method: "PATCH",
        body: data,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      invalidatesTags: ['Doctor']
    }),
    deleteDoctor: builder.mutation<any, any>({
      query: ({ doctorId }) => ({
        url: `/doctor/${doctorId}`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getTags: builder.query<any, any>({
      query: ({ q }) => ({
        url: `/doctor/tags${q ? "?q=${q}" : ""}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDoctorsQuery,
  useUpdateDoctorMutation,
  useDeleteDoctorMutation,
  useGetTagsQuery,
  usePrefetch,
} = doctorApi;
