const RoundedCancel = ({
  active,
  size = { width: "20", height: "20" },
}: {
  active: boolean;
  size?: { width: string; height: string };
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 13.333299 13.33329"
      fill="none"
      className="group/cancel"
    >
      <path
        d="M 13.3333,6.66667 C 13.3333,2.98477 10.3485,0 6.66667,0 2.98477,0 0,2.98477 0,6.66667 c 0,3.68182 2.98477,6.66662 6.66667,6.66662 3.68183,0 6.66663,-2.9848 6.66663,-6.66662 z"
        fill={active ? "#FE7862" : "#E0E0E0"}
        className={!active ? "group-hover/cancel:fill-[#FE7862]" : ""}
      />
      <path
        d="m 8.6637,8.66669 -3.99964,-4 m 4.3e-4,4 3.99961,-4"
        stroke={active ? "#FFFFFF" : "#4F4F4F"}
        className={!active ? "group-hover/cancel:stroke-[#FFFFFF]" : ""}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedCancel;
