import { SENIORITIES } from "@/constants";
import Button from "../Button";
import SeniorityIcon from "../Icons/SeniorityIcon";
import Typography from "../Typography";

export const getSenioritiesNavTabs = (
  disabledSeniorites: number[]
): INavTab[] => {
  return SENIORITIES.map((seniority) => ({
    id: seniority.id,
    label: () => (
      <Typography
        tag="span"
        className={`${seniority.textColor} whitespace-nowrap`}
      >
        {seniority.label}
      </Typography>
    ),
    icon: <SeniorityIcon color={seniority.colorCode} />,
    disabled: disabledSeniorites.includes(seniority.id),
  }));
};

export interface INavTab {
  id: number;
  label: React.FC<{ isActive: boolean }>;
  icon?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
}

interface NavTabProps {
  tabs: INavTab[];
  initialTab?: number | string;
  tabType: "neumorphism" | "flat";
  activeId: number;
  onChange?: (tabId: INavTab["id"]) => void;
  className?: string;
  classNames?: {
    tabs?: string;
  };
}

const NavTab: React.FC<NavTabProps> = ({
  activeId,
  tabs,
  tabType,
  onChange,
  className,
  classNames,
}) => {
  const neumorphism = `!rounded-full p-1.5`;

  return (
    <div
      className={`flex flex-col text-black overflow-y-auto overflow-x-hidden ${
        tabType === "neumorphism" ? "rounded-full" : "rounded-3xl"
      } ${className ?? ""}`}
    >
      <div
        className={`w-full mx-auto ${
          tabType == "neumorphism"
            ? neumorphism
            : "bg-transparent border-primary border-2 p-1.5 rounded-3xl"
        }`}
      >
        <div
          className={`flex justify-between gap-x-3 ${classNames?.tabs ?? ""}`}
        >
          {tabs.map((tab) => (
            <Button
              type="button"
              key={tab.id}
              variant={tabType == "neumorphism" ? "ghost" : "primary"}
              onClick={() => {
                onChange?.(tab.id);
              }}
              disabled={tab.disabled ?? false}
              className={`
            transition-all duration-500 ease-in-out w-full justify-center py-2 gap-3 px-6 !h-10  ${
              tab.disabled ? "blur-[2px]" : ""
            }  ${
                tab.id == activeId && tabType == "neumorphism"
                  ? "outer-shadow bg-white !rounded-full"
                  : null
              }
               ${
                 tab.id == activeId && tabType == "flat"
                   ? "outer-shadow bg-secondary !rounded-2xl"
                   : "!bg-transparent"
               }
            `}
              icon={{
                content: tab.icon,
                position: "left",
              }}
            >
              <tab.label isActive={tab.id == activeId} />
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavTab;
