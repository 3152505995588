import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface SettingsState {
  dashboard: { columnType: "single" | "double" };
  setDashboard: (partialDashboard: {
    columnType?: "single" | "double";
  }) => void;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set) => ({
      setDashboard: (partialDashboard) =>
        set((state) => ({
          dashboard: { ...state.dashboard, ...partialDashboard },
        })),
      dashboard: { columnType: "double" },
    }),
    {
      name: "settings",
    }
  )
);
