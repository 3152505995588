import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import navBarBackground from "@/assets/images/BackgroundDetail2.png";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { TIME_ZONE } from "@/constants";

import CarotTwo from "../Icons/CarotTwo";
import Table from "../Icons/Table";
import { useSeniority } from "@/store/seniority.state";

const TopNavBar = () => {
  const { month, year, calenderBack, calenderNext } = useTableViewContext();
  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const dateTime = DateTime.fromObject({ year, month }, { zone: TIME_ZONE });

  const navigate = useNavigate();

  return (
    <div className="w-full h-[100px] bg-gray4 relative p-2">
      <div
        className="w-full h-full opacity-10 bg-[length:1000px_500px] absolute top-0 left-0"
        style={{
          backgroundImage: `url(${navBarBackground})`,
        }}
      ></div>
      <div className="h-[80px] flex items-center justify-between gap-2 relative z-10">
        <div className="flex w-full h-full items-center">
          <div className="w-fit text-xl bg-white text-green10 h-fit font-extrabold vertical-text -scale-100 rounded-sm shadow-xl py-2">
            {dateTime.toFormat("yyyy")}
          </div>
          <div className={"w-[40%]"}>
            <div className="w-fit text-7xl bg-white text-green10 font-black px-2 rounded-xl h-full shadow-xl">
              {dateTime.toFormat("LLLL")}
            </div>
          </div>
          <div className="flex flex-col-reverse h-full py-2">
            <div className="flex gap-2 z-10">
              <div
                className="cursor-pointer"
                onClick={() => {
                  calenderBack();
                }}
              >
                <CarotTwo type="left" pathFill={"black"} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  calenderNext();
                }}
              >
                <CarotTwo type="right" pathFill={"black"} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (!activeSeniorityId) {
              setActiveId(1);
            }
            navigate("/dashboard");
          }}
        >
          <Table isActive={true} />
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
