import { useGetLocationsQuery } from "@/api/locationApi";
import { useLocation } from "@/store/location.store";
import { useState } from "react";
import CarotTwo from "../Icons/CarotTwo";
import ClockThree from "../Icons/ClockThree";
import UserRequest from "../Icons/UserRequest";
import Location from "../Icons/Location";
import User from "../Icons/User";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

const MenuOptions = () => {
  const { isLoading: isLocationLoading, data: locationsData } =
    useGetLocationsQuery({
      seniority: 4,
      all: true,
    });

  const {
    activeId: activeLocationId,
    setActiveLocation,
    activeLocation,
  } = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const {
    tableState,
    setTableState,
    isOff,
    setIsOff,
    isShiftTimeToggle,
    setIsShiftTimeToggle,
  } = useTableViewContext();

  return (
    <div className="w-full h-[100px] py-2 pr-2 flex justify-between items-center bg-gray4">
      <div className="flex gap-1 h-[60px]">
        <div
          className="rounded-r-xl bg-green11 flex items-center relative transition-[width] duration-500 shadow-xl"
          style={{ width: isDrawerOpen ? "140px" : "60px" }}
        >
          <div className="rounded-r-xl bg-green12 w-[50px] h-full flex justify-center items-center text-white font-extrabold text-base">
            {activeLocation ? activeLocation.shortLabel : "ALL"}
          </div>
          {isDrawerOpen ? (
            <div className="grid grid-rows-2 grid-flow-col p-1 h-[90%] gap-1">
              {locationsData?.locations.map(
                (location: { shortLabel: string; _id: string }) => (
                  <div
                    key={location._id}
                    className={`row-span-1 ${
                      location._id === activeLocation?._id
                        ? "bg-green12 text-white"
                        : "bg-white text-green10"
                    } rounded-lg w-fit h-fit text-[8px] font-medium px-1 py-1 cursor-pointer`}
                    onClick={() => {
                      setActiveLocation(location);
                    }}
                  >
                    {location.shortLabel}
                  </div>
                )
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            className="absolute right-0 cursor-pointer"
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          >
            <CarotTwo
              type={isDrawerOpen ? "left" : "right"}
              pathFill={"green"}
              size={{ height: "10px", width: "8px" }}
            />
          </div>
        </div>
        {!isDrawerOpen && (
          <>
            <div
              className={`${
                activeLocation
                  ? "bg-white text-green10"
                  : "bg-green10 text-white"
              } w-[35px] rounded-lg shadow-xl flex items-center justify-center cursor-pointer`}
              onClick={() => {
                setActiveLocation(undefined);
              }}
            >
              <div>
                <div className="text-center font-semibold text-sm">ALL</div>
                <div className="text-center font-medium text-[8px]">ZONES</div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div
                className={`${
                  isOff ? "bg-green10" : "bg-white"
                } w-[35px] rounded-lg shadow-xl flex items-center justify-center cursor-pointer`}
                onClick={() => {
                  setIsOff(!isOff);
                }}
              >
                <div
                  className={`${
                    isOff ? "text-white" : "text-green10"
                  } font-semibold text-sm rounded-lg`}
                >
                  Off
                </div>
              </div>
              <div
                className={`${
                  isShiftTimeToggle ? "bg-green10" : "bg-white"
                } w-[35px] rounded-lg shadow-xl flex-grow flex items-center cursor-pointer`}
                onClick={() => {
                  setIsShiftTimeToggle(!isShiftTimeToggle);
                }}
              >
                <div className="w-full text-center flex flex-col gap-[2px]">
                  <div className="mx-auto w-fit">
                    <ClockThree isActive={isShiftTimeToggle} />
                  </div>
                  <div
                    className={`${
                      isShiftTimeToggle ? "text-white" : "text-green10"
                    } font-medium text-[6px] w-full`}
                  >
                    TOGGLE
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col gap-2 h-[85px] w-[100px]">
        <div className="flex h-full justify-between items-center">
          <div
            className={`${
              tableState === "users"
                ? "text-white bg-lightGreen4 "
                : "text-gray5 bg-gray4"
            } font-[450] text-[12px] cursor-pointer text-center rounded px-2`}
            onClick={() => {
              setTableState("users");
            }}
          >
            Users
          </div>{" "}
          <div
            className={`${
              tableState === "users" ? "bg-lightGreen4" : "bg-white"
            } shadow-xl rounded-lg h-[22px] w-[24px] flex justify-center items-center`}
          >
            <User pathFill={tableState === "users" ? "#FFFEF8" : "#71928D"} />
          </div>{" "}
        </div>
        <div className="flex h-full justify-between items-center">
          <div
            className={`${
              tableState === "location"
                ? "text-white bg-lightGreen4 "
                : "text-gray5 bg-gray4"
            } font-[450] text-[12px] cursor-pointer text-center rounded px-2`}
            onClick={() => {
              setTableState("location");
            }}
          >
            Location
          </div>
          <div
            className={`${
              tableState === "location" ? "bg-lightGreen4" : "bg-white"
            } shadow-xl rounded-lg h-[22px] w-[24px] flex justify-center items-center`}
          >
            <Location
              pathFill={tableState === "location" ? "#FFFEF8" : "#71928D"}
            />
          </div>{" "}
        </div>
        <div className="flex h-full justify-between items-center">
          <div
            className={`${
              tableState === "requests"
                ? "text-white bg-lightGreen4 "
                : "text-gray5 bg-gray4"
            } font-[450] text-[12px] cursor-pointer text-center rounded px-2`}
            onClick={() => {
              setTableState("requests");
            }}
          >
            Requests{" "}
          </div>
          <div
            className={`${
              tableState === "requests" ? "bg-lightGreen4" : "bg-white"
            } shadow-xl rounded-lg h-[22px] w-[24px] flex justify-center items-center`}
          >
            <UserRequest
              pathFill={tableState === "requests" ? "#FFFEF8" : "#71928D"}
            />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default MenuOptions;
