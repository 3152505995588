import Button from "../Button/index";
import Input from "../Input/index";
import Typography from "../Typography/index";
import { useModal } from "../../hooks/useModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { z } from "zod";
import { useCreateAdminUserMutation } from "../../api/organisationApi";
import { useEffect } from "react";

interface CreateAdminUserModalProps {
  onSubmit?: (password: string) => void;
  orgId: string;
}

export interface CreateDoctorForm {
  name: string;
  email: string;
}

const CreateAdminUserModal = (props: CreateAdminUserModalProps) => {
  const { onSubmit, orgId } = props;

  const { closeModal } = useModal();

  const [createAdminUser, { isLoading, data, isSuccess, isError, error }] =
    useCreateAdminUserMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Created admin user successfully");
      closeModal();
      onSubmit?.(data.password);
    }
    if (isError) {
      toast.error((error as any).data.message ?? "Something went wrong");
    }
  }, [isSuccess, data, isError, error]);

  const formik = useFormik<CreateDoctorForm>({
    initialValues: {
      name: "",
      email: "",
    },
    validate: (values) => {
      const schema = z.object({
        name: z.string().nonempty("Name is required"),
        email: z.string().email("Invalid email").nonempty("Email is required"),
      });
      const result = schema.safeParse(values);
      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      await createAdminUser({ ...values, orgId });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="outer-shadow bg-white w-full max-w-2xl px-8 py-6 mx-auto rounded-xl"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          return;
        }
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="">
        <Typography
          tag="div"
          className="font-semibold text-xl text-black2 mb-4"
        >
          Add New User
        </Typography>
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-4 mb-8">
        <div className="col-span-2 grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Name
            </Typography>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.name ? formik.errors.name : ""}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Email
            </Typography>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email ? formik.errors.email : ""}
              type="email"
              placeholder="example@gmail.com"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          type="button"
          disabled={isLoading}
          variant="bordered"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          className="flex justify-center text-center !rounded-xl"
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default CreateAdminUserModal;
