import { useState } from "react";
import { RotatingSquare } from "react-loader-spinner";

import Edit from "../Icons/Edit";
import Trash from "../Icons/Trash";
import RoundedCancelOutline from "../Icons/RoundedCancelOutline";
import RoundedTickOutline from "../Icons/RoundedTickOutline";

import handleResponse from "../../utils/handleResponse";

import {
  useDeleteScheduleLabelMutation,
  useUpdateScheduleLabelMutation,
} from "../../api/preferencesApi";
import { shiftsApi } from "../../api/shiftsApi";

type LabelItemProps = {
  label: {
    label: string;
    shortLabel: string;
    colorCode: string;
    _id: string;
  };
  index: number;
  refetchScheduleLabels: () => void;
};

const LabelItem = ({ label, index, refetchScheduleLabels }: LabelItemProps) => {
  const [updateScheduleLabel, { isLoading: isUpdateLoading }] =
    useUpdateScheduleLabelMutation();

  const [deleteScheduleLabel, { isLoading: isDeleteLoading }] =
    useDeleteScheduleLabelMutation();

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [editLabel, setEditLabel] = useState({
    label: label.label,
    shortLabel: label.shortLabel,
  });

  return (
    <tr className="font-semibold">
      <td className="flex items-center">
        {(isUpdateLoading || isDeleteLoading) && (
          <RotatingSquare height={"20px"} width={"20px"} />
        )}
        <div>{index + 1}.</div>
      </td>
      <td>
        {isEdit ? (
          <input
            className="outline-none w-24"
            value={editLabel.label}
            onChange={(e) => {
              setEditLabel({ ...editLabel, label: e.target.value });
            }}
          />
        ) : (
          <div className="flex items-center w-24">{label.label}</div>
        )}
      </td>
      <td>
        <div
          className="text-white p-1 rounded flex justify-center items-center text-sm w-fit"
          style={{ backgroundColor: label.colorCode }}
        >
          {isEdit ? editLabel.label : label.label}
        </div>
      </td>
      <td className="border-l-2 pl-2 border-black">
        {isEdit ? (
          <input
            className="outline-none w-12"
            value={editLabel.shortLabel}
            onChange={(e) => {
              if (e.target.value.length <= 3) {
                setEditLabel({ ...editLabel, shortLabel: e.target.value });
              }
            }}
          />
        ) : (
          <div className="flex items-center w-12">{label.shortLabel}</div>
        )}
      </td>
      <td>
        <div
          className="text-white p-1 rounded flex justify-center items-center text-sm w-fit"
          style={{ backgroundColor: label.colorCode }}
        >
          {isEdit ? editLabel.shortLabel : label.shortLabel}
        </div>
      </td>
      <td>
        {isEdit ? (
          <div className="flex gap-[6px]">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => {
                setIsEdit(false);
                setEditLabel({
                  label: label.label,
                  shortLabel: label.shortLabel,
                });
              }}
            >
              <RoundedCancelOutline
                size={{ width: "15", height: "16" }}
                expand={false}
              />
            </div>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={async () => {
                if (editLabel.label !== "" && editLabel.shortLabel !== "") {
                  const response = await updateScheduleLabel({
                    label: editLabel.label,
                    shortLabel: editLabel.shortLabel,
                    scheduleLabelId: label._id,
                  });
                  handleResponse(response, "Label added.", () => {
                    setIsEdit(false);
                    shiftsApi.util.invalidateTags(["Shifts"]);
                    refetchScheduleLabels();
                  });
                }
              }}
            >
              <RoundedTickOutline
                size={{ width: "15", height: "16" }}
                expand={false}
              />
            </div>
          </div>
        ) : (
          <div className="flex gap-[2px] items-center relative">
            <div
              onClick={() => {
                setIsEdit(true);
              }}
              className="cursor-pointer"
            >
              <Edit />
            </div>

            <div
              className={`${
                isDelete
                  ? "w-[170px] h-[32px] p-2 absolute transition-[width]"
                  : "w-0 h-0"
              } overflow-hidden bg-white rounded-md flex left-5`}
            >
              <div className="text-xs text-secondary">Delete this label ? </div>
              <div className="flex gap-[2px] ml-2">
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    // should set false on outside click
                    setIsDelete(false);
                  }}
                >
                  <RoundedCancelOutline
                    size={{ width: "15", height: "16" }}
                    expand={false}
                  />
                </div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={async () => {
                    const response = await deleteScheduleLabel({
                      scheduleLabelId: label._id,
                    });
                    // invalidate all shifts cache.
                    handleResponse(
                      response,
                      "Label deleted successfully.",
                      () => {
                        refetchScheduleLabels();
                      }
                    );
                  }}
                >
                  <RoundedTickOutline expand={false} />
                </div>
              </div>
            </div>
            {!isDelete && (
              <div
                onClick={async () => {
                  setIsDelete(true);
                }}
                className="cursor-pointer"
              >
                <Trash />
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default LabelItem;
