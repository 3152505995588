import { GROUP_COLORS } from "@/constants";
import { useEffect, useRef, useState } from "react";
import CarotTwo from "../Icons/CarotTwo";

const Cell = ({
  isActive,
  shifts,
  isOff,
  isTimeShow,
}: {
  isActive: boolean;
  shifts: any[];
  isOff: boolean;
  isTimeShow: boolean;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const timeRef = useRef<{
    fromDiv: null | HTMLDivElement;
    toDiv: null | HTMLDivElement;
  }>({ fromDiv: null, toDiv: null });

  const [triggerRender, setTriggerRender] = useState(false);
  const renderCount = useRef<number | false>(false);

  useEffect(() => {
    if (isTimeShow && renderCount.current === false) {
      renderCount.current = 0;
      setTriggerRender(!triggerRender);
    }
    if (
      isTimeShow &&
      renderCount.current !== false &&
      renderCount.current < 4
    ) {
      setTriggerRender(!triggerRender);
      renderCount.current += 1;
    } else if (isTimeShow && renderCount.current === 4) {
      renderCount.current = false;
    }
  }, [isTimeShow, triggerRender]);

  return (
    <div
      className={`h-full w-full ${
        isActive ? "bg-white" : "bg-gray8"
      } flex justify-center items-center relative`}
    >
      {}
      {shifts.length > 0 && !isOff && (
        <>
          <div className="absolute left-0 rounded-r-md bg-maroon1 p-[3px] text-white text-[8px] leading-none font-medium">
            LO
          </div>
          <div className="pt-2">
            <div className="flex items-center">
              {shifts.length > 1 && (
                <div
                  className="cursor-pointer pr-1"
                  onClick={() => {
                    if (activeIndex > 0) {
                      setActiveIndex(activeIndex - 1);
                    } else {
                      setActiveIndex(shifts.length - 1);
                    }
                  }}
                >
                  <CarotTwo
                    type="left"
                    pathFill={"#E9E9E9"}
                    size={{ width: "5px", height: "8px" }}
                  />
                </div>
              )}

              {isTimeShow && (
                <div
                  className={`rounded-lg bg-white shadow-xl relative ${
                    !isTimeShow ? "hidden" : ""
                  }`}
                  style={{
                    width:
                      timeRef.current.fromDiv && timeRef.current.toDiv
                        ? timeRef.current.fromDiv.offsetWidth +
                          timeRef.current.toDiv.offsetWidth
                        : 80,
                  }}
                >
                  <div
                    className={`rounded-lg ${GROUP_COLORS["morning"].bgColor3} p-1`}
                  >
                    <div
                      className="flex w-fit"
                      ref={(ref) => {
                        timeRef.current.fromDiv = ref;
                      }}
                    >
                      <div
                        className={`rounded-lg ${GROUP_COLORS["morning"].bgColor3} text-white font-medium text-sm`}
                      >
                        8
                      </div>{" "}
                      <div
                        className={`rounded-lg ${GROUP_COLORS["morning"].bgColor3} text-white font-medium text-[8px] pt-[5px] pl-[1px]`}
                      >
                        AM
                      </div>
                    </div>
                  </div>
                  <div className="h-3"></div>
                  <div className="flex flex-row-reverse w-full absolute right-0 bottom-0">
                    <div className="flex">
                      <div
                        className={`${GROUP_COLORS["morning"].textColor2} text-[7px] font-medium h-fit leading-none pt-4`}
                      >
                        TO
                      </div>
                      <div
                        className={`bg-white rounded-lg text-[10px] leading-none font-medium ${GROUP_COLORS["morning"].textColor2} flex flex-nowrap items-center p-[2px]`}
                        ref={(ref) => {
                          timeRef.current.toDiv = ref;
                        }}
                      >
                        3:30 PM
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isTimeShow && (
                <div
                  className={`rounded-lg ${GROUP_COLORS["morning"].bgColor3} flex`}
                >
                  <div className="rounded-lg bg-white flex">
                    <div
                      className={`rounded-lg ${
                        isActive
                          ? "bg-gray9 text-green12"
                          : "bg-gray10 text-white"
                      }  h-fit font-medium text-xs p-1`}
                    >
                      Clinic
                    </div>
                    <div className="h-full w-[1px]"></div>
                  </div>
                  <div className="h-full w-[4px]"></div>
                </div>
              )}
              {shifts.length > 1 && (
                <div
                  className="cursor-pointer pl-1"
                  onClick={() => {
                    if (activeIndex < shifts.length - 1) {
                      setActiveIndex(activeIndex + 1);
                    } else {
                      setActiveIndex(0);
                    }
                  }}
                >
                  <CarotTwo
                    type="right"
                    pathFill={"#E9E9E9"}
                    size={{ width: "5px", height: "8px" }}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-center mt-1 gap-[1px]">
              {Array(shifts.length)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className={`w-[5px] h-[5px] rounded-full ${
                      index === activeIndex ? "bg-lightGreen5" : "bg-gray9"
                    }`}
                  ></div>
                ))}
            </div>
          </div>
          {!isTimeShow && (
            <div className="absolute right-0 bottom-0 pb-[0.5px]">
              <div
                className={`${GROUP_COLORS["morning"].textColor2} font-medium leading-none flex flex-row-reverse pr-[0.5px]`}
              >
                8
              </div>
              <div
                className={`rounded-l ${GROUP_COLORS["morning"].bgColor4} p-[2px] text-white text-[8px] leading-none font-medium`}
              >
                AM
              </div>
            </div>
          )}
        </>
      )}
      {isOff && (
        <div className=" rounded-l-full rounded-r-full bg-green13 text-white px-2 font-medium">
          Off
        </div>
      )}
    </div>
  );
};

export default Cell;
