import { Dispatch, useEffect, useState } from "react"
import Button from "../Button/index"
import EyeToggle from "../Icons/Eye"
import Input from "../Input/index"
import { ThreeDots } from "react-loader-spinner"
import { useLocation, useNavigate } from "react-router-dom"
import api from "../../api/api"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { z } from "zod"

interface ResetPasswordForm {
  password: string
  confirmPassword: string
}

interface Props {
  onSubmit?: (
    values: ResetPasswordForm,
    setLoading: Dispatch<React.SetStateAction<boolean>>
  ) => void
  title?: string
}

const ResetPasswordComponent = (props: Props) => {
  const { onSubmit, title = "Reset password!" } = props
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const navigate = useNavigate()

  const { fetchFn: setNewPassword } = api.auth.useSetNewPassword()

  useEffect(() => {
    if (onSubmit) {
      return
    }

    if (!state?.token || !state?.email) {
      navigate("/")
    }
  }, [])

  const formik = useFormik<ResetPasswordForm>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const schema = z.object({
        password: z
          .string({ required_error: "Password is required!" })

          .nonempty("Password is required!"),
        confirmPassword: z
          .string({ required_error: "Confirm Password is required!" })
          .refine((data) => data === values.password, {
            message: "Passwords do not match",
          }),
      })

      const result = schema.safeParse(values)

      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0]
          return acc
        }, {} as Record<string, string>)

        return errors
      }
      return {}
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      if (onSubmit) {
        onSubmit(values, setIsLoading)
        return
      }

      const reset = await setNewPassword({
        data: {
          newPassword: values.password,
        },
        headers: {
          Authorization: `${state?.token}`,
        },
      })

      const { isSuccess, data, isError, error } = reset.unwrap()

      if (isSuccess) {
        toast.success(data?.message ?? "Password reset successfully")
        setIsLoading(false)
        navigate("/")
      }

      if (isError) {
        toast.error(error?.response?.data?.message ?? "Something went wrong")
        setIsLoading(false)
      }

      setIsLoading(false)
    },
  })

  return (
    <div className="!max-w-xl py-8 px-12 w-full rounded-xl bg-white h-fit outer-shadow">
      <div className="mb-8 dm font-bold text-4xl text-black">{title}</div>
      {/* <div className="mb-16 flex justify-center"></div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6">
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.password ? formik.errors.password : ""}
            placeholder="Enter Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleShowPassword}
                  state={showPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
          <Input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ""
            }
            placeholder="Confirm Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleShowPassword}
                  state={showPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
        </div>

        <div className="mt-8 mb-4">
          <Button
            disabled={isLoading}
            className="flex justify-center items-center"
            name="Submit"
            type="submit"
          >
            {isLoading ? (
              <ThreeDots
                height={80}
                width="80"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ResetPasswordComponent
