import { forwardRef, useEffect, useState } from "react";
import Button from "../Button";
import { HiPlus } from "react-icons/hi";
import { IoMdClose, IoMdPin } from "react-icons/io";
import { useModal } from "../../hooks/useModal";
import LocationModal from "../Modals/LocationModal";
import { useCreateLocation } from "../../store/location.store";
import RoundedCalendar from "../Calender/RoundedCalander";
import { formatDateAPI } from "../../utils/formatDate";
import { useCalendar } from "../../store/calendar.state";

const AddLocation = () => {
  const [showAddLocation, setShowAddLocation] = useState(true);

  const { setLocationLabel, setSeniorities, setGroups } = useCreateLocation();

  const handleAddLocation = () => {
    setShowAddLocation(!showAddLocation);
    if (showAddLocation) {
      setLocationLabel("");
      setSeniorities([]);
      setGroups([]);
    }
  };

  return (
    <div className="">
      {showAddLocation ? (
        <Button
          className="!bg-white !w-9 !h-9 !rounded-full border ring-secondary"
          onClick={handleAddLocation}
        >
          <HiPlus className="text-secondary h-6 w-6 mx-auto cursor-pointer" />
        </Button>
      ) : (
        <InputGroup onClose={handleAddLocation} />
      )}
    </div>
  );
};

interface InputGroupProps {
  onClose: () => void;
}

const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>(
  (props, ref) => {
    const { onClose } = props;
    const { label, setLocationLabel, setClosedDates } = useCreateLocation();

    useEffect(() => {
      setLocationLabel("");
    }, []);

    const { openModal, closeModal } = useModal();

    const { month, year } = useCalendar();

    const handleInputChange = (e: any) => {
      setLocationLabel(e.target.value);
    };

    return (
      <div className="h-9">
        <form className="flex absolute z-50 left-7 w-fit ring-2 bg-white ring-secondary gap-1 rounded-full">
          <span className="flex items-center text-center justiify-center ring-2 ring-secondary w-10 text-base text-gray-900 rounded-full">
            <IoMdPin className=" text-secondary mx-auto fa-lg " size={22} />
          </span>
          <input
            ref={ref}
            type="search"
            id="website-admin"
            placeholder="Add Location"
            className="rounded-none h-10 bg-white w-44 font-DM outline-none text-gray-900 pl-3 block flex-1 min-w-0 text-base"
            onChange={handleInputChange}
          />

          {label.length > 0 ? (
            <button
              type="submit"
              className="inline-flex text-center cursor-pointer mx-auto justify-center font-DM font-bold items-center !rounded-full text-sm text-white bg-secondary  w-10"
              onClick={() => {
                onClose();
                openModal(
                  <RoundedCalendar
                    onCancel={() => {
                      closeModal();
                    }}
                    initial={{ month, year }}
                    showFooter={true}
                    onSubmit={(dates) => {
                      setClosedDates(dates.map((date) => formatDateAPI(date)));
                      closeModal();
                      openModal(<LocationModal />);
                    }}
                  />
                );
              }}
            >
              <HiPlus className=" text-white mx-auto fa-lg " size={25} />
            </button>
          ) : (
            <span
              className="inline-flex text-center cursor-pointer mx-auto justify-center font-DM font-bold items-center !rounded-full text-sm text-white bg-red-500 ring-red-500 w-10"
              onClick={() => onClose()}
            >
              <IoMdClose className=" text-white mx-auto fa-lg " size={25} />
            </span>
          )}
        </form>
      </div>
    );
  }
);
export default AddLocation;
