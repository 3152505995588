import { Key } from "./components/Dashboard/DayView";

export const TIME_ZONE = "Asia/Kolkata";
export const DISPLAY_DATE_FORMAT = "ccc, dd MMM";

export const DISPLAY_DATE_FORMAT_LONG = "ccc, MMMM yyyy";
export const API_DATE_FORMAT = "dd/MM/yyyy";
export const API_TIME_FORMAT = "h:mm a";

export const DATE_RANGE = 1;

export const CACHE_LIFE = 4 * 3600;

export const SENIORITIES = [
  {
    id: 1,
    colorCode: "#E57041",
    textColor: "text-[#E57041]",
    label: "Senior",
    shortLabel: "Sr",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 2,
    colorCode: "#84ACE2",
    textColor: "text-[#84ACE2]",
    label: "Mid Level",
    shortLabel: "Mid",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 3,
    colorCode: "#DF9C28",
    textColor: "text-[#DF9C28]",
    label: "Junior",
    shortLabel: "Jr",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
];

export const BG_SENIORITY = Object.fromEntries(
  SENIORITIES.map((seniority) => {
    return [seniority.id, `bg-[${seniority.colorCode}]`];
  })
);

export const GROUP_COLORS: Record<
  Key,
  {
    bgColor: string;
    bgColor2: string;
    bgColor3: string;
    bgColor4: string;
    textColor: string;
    textColor2: string;
    borderColor: string;
  }
> = {
  morning: {
    bgColor: "bg-green6",
    bgColor2: "bg-green8",
    bgColor3: "bg-orange5",
    bgColor4: "bg-yellow2",
    textColor: "text-green7",
    textColor2: "text-yellow2",
    borderColor: "border-green6",
  },
  noon: {
    bgColor: "bg-teal4",
    bgColor2: "bg-teal5",
    bgColor3: "bg-green6",
    bgColor4: "bg-green14",
    textColor: "text-teal4",
    textColor2: "text-green14",
    borderColor: "border-teal4",
  },
  night: {
    bgColor: "bg-blue1",
    bgColor2: "bg-blue5",
    bgColor3: "bg-green8",
    bgColor4: "bg-blue1",
    textColor: "text-blue1",
    textColor2: "text-blue1",
    borderColor: "border-blue1",
  },
  standBy: {
    bgColor: "bg-secondary",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    textColor: "text-white",
    textColor2: "",
    borderColor: "border-secondary",
  },
  dayOff: {
    bgColor: "bg-lightGreen2",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    textColor: "text-white",
    textColor2: "",
    borderColor: "border-lightGreen2",
  },
};

// Table Page

export const NUMBER_OF_DOCTORS = 10;
export const ROW_HEIGHT = 60;
