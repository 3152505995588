import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getGroups: builder.query<any, any>({
      query: ({ seniority, allowSenior, q, includeDoctors }) => ({
        url: `group?seniority=${seniority}${
          allowSenior ? `&allowSenior=${allowSenior}` : ""
        }${q ? `&q=${q}` : ""}${
          includeDoctors !== undefined ? `&includeDoctors=${includeDoctors}` : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const { useGetGroupsQuery } = groupApi;
