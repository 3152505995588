import { MutableRefObject, useEffect, useRef, useState } from "react";

const useKey = (
  ref: MutableRefObject<HTMLElement | null>,
  keys: string[]
): {
  isKeyPressed: Record<string, boolean>;
  setKeyPressed: React.Dispatch<
    React.SetStateAction<{
      [k: string]: boolean;
    }>
  >;
} => {
  const [isKeyPressed, setKeyPressed] = useState(
    Object.fromEntries(keys.map((key) => [key, false]))
  );

  const isKeyPressedRef = useRef(
    Object.fromEntries(keys.map((key) => [key, false]))
  );
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (keys.includes(e.key) && !isKeyPressedRef.current[e.key]) {
        e.preventDefault();
        isKeyPressedRef.current = { ...isKeyPressedRef.current, [e.key]: true };
        setKeyPressed({ ...isKeyPressed, [e.key]: true });
      }
    };

    const keyUpHandler = (e: KeyboardEvent) => {
      if (keys.includes(e.key) && isKeyPressedRef.current[e.key]) {
        e.preventDefault();
        isKeyPressedRef.current = {
          ...isKeyPressedRef.current,
          [e.key]: false,
        };
        setKeyPressed({ ...isKeyPressed, [e.key]: false });
      }
    };

    ref.current?.addEventListener("keydown", keyDownHandler);
    ref.current?.addEventListener("keyup", keyUpHandler);

    const cleanUpCopy = ref.current;

    return () => {
      cleanUpCopy?.removeEventListener("keydown", keyDownHandler);
      cleanUpCopy?.removeEventListener("keyup", keyUpHandler);
    };
  }, [ref]);

  return { isKeyPressed, setKeyPressed };
};

export default useKey;
