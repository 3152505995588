export function getSeniority(seniority: number | undefined) {
  switch (seniority) {
    case 1:
      return "senior";
    case 2:
      return "midlevel";
    case 3:
      return "junior";
    default:
      return "blue";
  }
}
