import { AxiosRequestConfig } from "axios";
import { axiosApi } from "../api/axios";

type Unwrap<B, R, E> = () => Omit<ApiState<B, R, E>, "fetchFn">;
export interface ApiState<Body, Response, ErrorType> {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: ErrorType;
  data: Response;
  fetchFn: (
    args?: FetchFnArgs<Body>
  ) => Promise<{ unwrap: Unwrap<Body, Response, ErrorType> }>;
}

type SetFunc<T> = (
  partial: T | Partial<T> | ((state: T) => T | Partial<T>),
  replace?: boolean | undefined
) => void;

type FetchFnArgs<Body> = {
  data?: Body;
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
};

export const getInitialState = <Body, Response, ErrorType>(
  config: AxiosRequestConfig,
  set: SetFunc<ApiState<Body, Response, ErrorType>>
): ApiState<Body, Response, ErrorType> => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null as unknown as ErrorType,
  data: null as unknown as Response,
  fetchFn: async (
    args?: FetchFnArgs<Body>
  ): Promise<{ unwrap: Unwrap<Body, Response, ErrorType> }> => {
    let data: Response = null as unknown as Response;
    let error: ErrorType = null as unknown as ErrorType;
    let isError = false;
    let isLoading = true;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null as unknown as Response,
        error: null as unknown as ErrorType,
      });
      isLoading = true;
      const res = await axiosApi({ ...config, ...args });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: (): Omit<ApiState<Body, Response, ErrorType>, "fetchFn"> => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
});
