import { HiSearch } from "react-icons/hi";
import RulesCard from "../Cards/RulesCard";
import Input from "../Input/index";
import GroupPill from "../Pills/GroupPill";
import Button from "../Button";
import { useEffect, useRef, useState } from "react";
import api from "../../api/api.ts";
import { useOutsideClick } from "rooks";
import { useSeniority } from "../../store/seniority.state";
import { toast } from "react-toastify";
import Table from "../Table";
import { useGetGroupsQuery } from "../../api/groupApi.ts.ts";

const RulesManagementTab = () => {
  const [group, setGroup] = useState<any>({});

  const { activeId: activeSeniorityId } = useSeniority();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [expand, setExpand] = useState<boolean>(false);
  const dropdownArea = useRef<HTMLDivElement>(null);

  const [minRestBetweenShifts, setMinRestBetweenShifts] = useState<number>(0);
  const [maxHoursPerWeek, setMaxHoursPerWeek] = useState<number>(0);
  const [offsPerWeek, setOffsPerWeek] = useState<number>(0);

  const [minRestBetweenShiftsChecked, setMinRestBetweenShiftsChecked] =
    useState<boolean>(false);
  const [maxHoursPerWeekChecked, setMaxHoursPerWeekChecked] =
    useState<boolean>(false);
  const [offsPerWeekChecked, setOffsPerWeekChecked] = useState<boolean>(false);


  const {
    isLoading: isGroupsLoading,
    data: groups,
  } = useGetGroupsQuery({ seniority: activeSeniorityId, q: searchQuery });

  const {
    fetchFn: getRules,
    isLoading: isRulesLoading,
    data: rulesData,
  } = api.rules.useGetRulesByGroupId();

  const { fetchFn: applyRules, isLoading: isRuleApplyLoading } =
    api.rules.useApplyRule();

  // const { seniorities } = useSeniority();

  useEffect(() => {
    if (rulesData?.minRestBetweenShifts) {
      setMinRestBetweenShifts(rulesData?.minRestBetweenShifts?.value);
      setMinRestBetweenShiftsChecked(rulesData?.minRestBetweenShifts?.enabled);
    }
    if (rulesData?.maxHoursPerWeek) {
      setMaxHoursPerWeek(rulesData?.maxHoursPerWeek?.value);
      setMaxHoursPerWeekChecked(rulesData?.maxHoursPerWeek?.enabled);
    }
    if (rulesData?.offsPerWeek) {
      setOffsPerWeek(rulesData?.offsPerWeek?.value);
      setOffsPerWeekChecked(rulesData?.offsPerWeek?.enabled);
    }
  }, [rulesData]);

  useEffect(() => {
    getRules({
      params: {
        groupId: group._id ?? null,
        seniority: activeSeniorityId,
      },
    });
  }, [activeSeniorityId, group]);

  useOutsideClick(dropdownArea, () => {
    setExpand(false);
  });

  const {
    fetchFn: getAllRules,
    isLoading: isAllRuleLoading,
    data: allRules,
  } = api.rules.useGetAllRules();

  useEffect(() => {
    getAllRules();
  }, []);

  return (
    <div className="flex-grow overflow-auto pt-8 w-full max-w-[95%] mx-auto">
      <div className="flex flex-wrap items-center gap-4">
        <div ref={dropdownArea} className="relative">
          <Input
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            type="text"
            onFocus={() => setExpand(true)}
            icon={{
              content: <HiSearch size={20} />,
              position: "left",
            }}
            placeholder="Search Group or People..."
            className="!text-sm placeholder:text-sm !w-72 !rounded-2xl !placeholder:text-sm"
          />
          {expand ? (
            <div className="flex flex-col w-full gap-y-4 max-h-72 overflow-y-auto absolute top-full p-3 left-0 rounded-lg bg-white drop-shadow-lg">
              {isGroupsLoading ? (
                <div className="flex flex-col h-full mt-10 text-center items-center justify-center text-black3 text-base font-semibold">
                  Loading...
                </div>
              ) : (
                groups?.map((group: any) => (
                  <div
                    onClick={() => {
                      setGroup(group);
                      setExpand(false);
                    }}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="w-3 h-3 rounded-full bg-secondary"></div>
                    <div className="text-black1 text-sm font-semibold">
                      {group.title}
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : null}
        </div>

        {Object.keys(group).length ? (
          <GroupPill
            name={group?.title}
            onDelete={() => {
              setGroup({});
            }}
            className={""}
          />
        ) : null}
      </div>
      <div className="flex flex-wrap mt-8 gap-8">
        <RulesCard
          isChecked={minRestBetweenShiftsChecked}
          onChange={(e) => {
            setMinRestBetweenShiftsChecked(e.target.checked);
          }}
          name={"Sub Group: Minimum Rest Between Shifts"}
          label={{
            name: "Select Rest Time (In Hours)",
            content: (
              <div className="">
                {" "}
                <Input
                  type="number"
                  min="0"
                  placeholder="Eg. 3"
                  value={minRestBetweenShifts}
                  onChange={(e) => {
                    setMinRestBetweenShifts(Number(e.target.value));
                  }}
                />
              </div>
            ),
          }}
          id={"rule-1"}
        />
        <RulesCard
          isChecked={maxHoursPerWeekChecked}
          onChange={(e) => {
            setMaxHoursPerWeekChecked(e.target.checked);
          }}
          name={"Sub Group: Hours Per Week"}
          label={{
            name: "Total Working Hours Per Week",
            content: (
              <div className="">
                {" "}
                <Input
                  type="number"
                  min="0"
                  placeholder="Eg. 3"
                  value={maxHoursPerWeek}
                  onChange={(e) => {
                    setMaxHoursPerWeek(Number(e.target.value));
                  }}
                />
              </div>
            ),
          }}
          id={"rule-2"}
        />
        <RulesCard
          isChecked={offsPerWeekChecked}
          onChange={(e) => {
            setOffsPerWeekChecked(e.target.checked);
          }}
          name={"Sub Group: Number of Offs Per Week"}
          label={{
            name: "Total Offs Per Week (In Day)",
            content: (
              <div className="">
                {" "}
                <Input
                  type="number"
                  min="0"
                  placeholder="Eg. 3"
                  value={offsPerWeek}
                  onChange={(e) => {
                    setOffsPerWeek(Number(e.target.value));
                  }}
                />
              </div>
            ),
          }}
          id={"rule-3"}
        />
      </div>
      <div className="w-full flex p-8 justify-center items-center">
        <Button
          className="w-min px-9"
          disabled={isRuleApplyLoading || isRulesLoading}
          onClick={async () => {
            // const apply = await applyRules({
            //   data: {
            //     seniority: activeSeniorityId,
            //     forGroups: group?._id ? [group._id] : [],
            //     rule: {
            //       minRestBetweenShifts: {
            //         enabled: minRestBetweenShiftsChecked,
            //         value: minRestBetweenShifts,
            //       },
            //       maxHoursPerWeek: {
            //         enabled: maxHoursPerWeekChecked,
            //         value: maxHoursPerWeek,
            //       },
            //       offsPerWeek: {
            //         enabled: offsPerWeekChecked,
            //         value: offsPerWeek,
            //       },
            //     },
            //   },
            // });

            // const { isError, isSuccess, error } = apply.unwrap();

            // if (isError) {
            //   toast.error(error?.response?.data?.message);
            // }

            // if (isSuccess) {
            //   toast.success("Rules Applied Successfully");
            //   getRules({
            //     params: {
            //       groupId: group._id ?? null,
            //       seniority: activeSeniorityId,
            //     },
            //   });
            // }
          }}
        >
          {isRuleApplyLoading ? "Loading..." : "Submit"}
        </Button>
      </div>
      <Table
        className="w-full"
        data={allRules?.rules ?? []}
        skeleton={isAllRuleLoading}
        head={[
          {
            key: "rule",
            title: "Max Hrs Per Week",
            render: (rule: any) => {
              return <>{rule.maxHoursPerWeek.value}</>;
            },
          },
          {
            key: "rule",
            title: "Min Rest Between Shifts",
            render: (rule: any) => {
              return <>{rule.minRestBetweenShifts.value}</>;
            },
          },
          {
            key: "rule",
            title: "Offs Per Week",
            render: (rule: any) => {
              return <>{rule.offsPerWeek.value}</>;
            },
          },
          {
            key: "_id",
            title: "Group",
            render: (rule: any, parent: any) => {
              const group = groups?.find(
                (group: any) => group._id === parent.groupId
              );

              const seniority = parent?.seniority;
              // const sen = seniorities.find((s) => s.id === seniority);

              // return <>{group?.title ?? sen?.label}</>;
            },
          },
        ]}
      />
    </div>
  );
};

export default RulesManagementTab;
