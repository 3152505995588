const Location = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
    >
      <path
        d="M4.93871 13.5355C4.78199 13.5355 4.64766 13.4907 4.53571 13.4012C4.42377 13.3116 4.33981 13.1941 4.28384 13.0485C4.07114 12.4217 3.80247 11.8339 3.47784 11.2854C3.16439 10.7369 2.72221 10.0932 2.15129 9.35438C1.58038 8.61554 1.11581 7.91029 0.757587 7.23863C0.410559 6.56696 0.237045 5.75536 0.237045 4.80384C0.237045 3.49409 0.69042 2.38584 1.59717 1.47909C2.51511 0.561145 3.62896 0.102173 4.93871 0.102173C6.24846 0.102173 7.35671 0.561145 8.26346 1.47909C9.1814 2.38584 9.64038 3.49409 9.64038 4.80384C9.64038 5.82253 9.44447 6.67331 9.05267 7.35617C8.67206 8.02784 8.22988 8.6939 7.72613 9.35438C7.12163 10.1604 6.66265 10.832 6.34921 11.3694C6.04696 11.8955 5.79509 12.4552 5.59359 13.0485C5.53761 13.2053 5.44806 13.3284 5.32492 13.418C5.21297 13.4963 5.08424 13.5355 4.93871 13.5355ZM4.93871 6.483C5.40888 6.483 5.80628 6.32068 6.13092 5.99605C6.45556 5.67141 6.61788 5.274 6.61788 4.80384C6.61788 4.33367 6.45556 3.93627 6.13092 3.61163C5.80628 3.28699 5.40888 3.12467 4.93871 3.12467C4.46854 3.12467 4.07114 3.28699 3.7465 3.61163C3.42186 3.93627 3.25954 4.33367 3.25954 4.80384C3.25954 5.274 3.42186 5.67141 3.7465 5.99605C4.07114 6.32068 4.46854 6.483 4.93871 6.483Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Location;
