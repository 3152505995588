/**
 * @description Adds element to array if the element exists in the array or removes element from the array if the element does not exist in the array.
 * @param array
 * @param element
 * @returns
 */
const addOrRemove = <T>(array: T[], element: T) => {
  if (array.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    array.push(element);
    return [...array];
  }
};

export default addOrRemove;
