import { Link } from "react-router-dom";
import { useState } from "react";
import { z } from "zod";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { signInWithCustomToken } from "firebase/auth";

import { useFormik } from "formik";
import Button from "../Button";
import Input from "../Input";
import EyeToggle from "../Icons/Eye";
import api from "../../api/api";
import { getRedirectUrl } from "../../utils/getRedirectUrl";
import { auth } from "../../firebase";

interface LoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { fetchFn: login, isLoading } = api.auth.useLogin();

  const formik = useFormik<LoginForm>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      const schema = z.object({
        email: z
          .string({ required_error: "Email is required!" })
          .email("Invalid email!")
          .nonempty("Email is required!"),
        password: z
          .string({ required_error: "Password is required!" })
          .nonempty("Password is required!"),
      });
      const result = schema.safeParse(values);
      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors,
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0];
          return acc;
        }, {} as Record<string, string>);
        return errors;
      }
      return {};
    },
    onSubmit: async (values) => {
      const res = await login({
        data: { ...values, allowedRoles: [0, 1] },
      });
      const { data, error, isError, isSuccess } = res.unwrap();

      if (isSuccess) {
        localStorage.setItem("token", data.token);
        await signInWithCustomToken(auth, data.firebaseToken);
        location.replace(getRedirectUrl(data.role));
      } else if (isError) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="!max-w-xl py-8 px-12 w-full rounded-xl bg-white h-fit shadow-lg">
      <div className="mb-8 mt-2 dm font-bold text-[40px] leading-10 gap-4 text-black">
        <div className="flex gap-4 items-center">
          <div className="col-span-2">
            <svg
              width="120"
              height={120}
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4614_93678)">
                <path
                  d="M0.0802512 36.7534C0.0276421 36.2791 0 35.7984 0 35.3098V29.8616C2.379 29.6771 4.76157 29.4978 7.14057 29.4559C9.0015 29.4238 10.8526 29.571 12.6761 29.9535C14.2446 30.2825 15.962 30.6784 17.3164 31.5719C18.5987 32.4145 19.2531 33.7503 19.608 35.201C16.5059 36.9692 12.9329 37.5203 9.40454 37.6255C6.25692 37.7173 3.14139 37.4186 0.0811428 36.7525L0.0802512 36.7534Z"
                  fill="#000000"
                />
                <path
                  d="M20.0479 38.7729C20.2209 41.8474 20.2111 44.9255 20.0408 48H12.6907C7.38519 48 2.83941 44.7463 0.947266 40.122C3.88535 40.6517 6.87872 40.8728 9.86139 40.748C13.3594 40.6009 16.8736 40.0338 20.0346 38.5215C20.039 38.6044 20.0435 38.69 20.0479 38.772V38.7729Z"
                  fill="#000000"
                />
                <path
                  d="M29.1893 11.4349C28.8736 7.63278 28.7586 3.8155 28.8228 0H35.3071C36.6633 0 37.9687 0.21222 39.1939 0.608125C39.2956 3.90556 39.3277 7.21012 38.996 10.496C38.683 13.6088 38.1168 18.0235 34.1791 18.3231C32.698 18.4364 31.2294 17.7061 30.3565 16.5112C29.3248 15.097 29.3275 13.1077 29.1893 11.4358V11.4349Z"
                  fill="#000000"
                />
                <path
                  d="M48.0009 12.6922V26.3152C43.9277 26.26 39.8563 26.4098 35.8009 26.7923C32.5748 27.0964 29.4825 27.9301 27.8498 31.0064C26.342 33.8455 26.8369 37.3409 29.0242 39.6922C31.3978 42.2433 35.3587 42.559 38.6195 42.8399C40.8692 43.0307 43.1279 43.0628 45.3847 43.0262C43.0654 46.0499 39.414 48 35.3078 48H23.1837C23.3745 44.617 23.3905 41.2116 23.1284 37.8384C22.8823 34.6997 22.177 31.363 19.5572 29.33C16.6673 27.0874 12.3105 26.4749 8.75718 26.334C5.85833 26.2189 2.91758 26.4606 0 26.7067V19.23C1.70667 20.4159 3.79053 21.1872 5.53376 21.477C9.21818 22.0869 12.99 21.9719 16.7645 21.1435C18.3784 20.7886 19.8292 19.7828 20.6861 18.3178C21.5171 16.8947 21.7151 15.0132 21.6259 13.3921C21.3959 9.32786 15.8969 9.66402 12.9953 9.85752C10.9899 9.99305 10.9739 13.1318 12.9953 12.9962C14.3266 12.9088 15.6641 12.9062 16.9927 13.0471C17.3102 13.0818 18.1992 13.0676 18.4462 13.344C18.5773 13.4911 18.4827 14.2303 18.4667 14.5486C18.4159 15.6196 18.2411 16.768 17.3227 17.4502C16.4042 18.1341 15.1648 18.3784 14.0645 18.5835C11.1817 19.1203 8.82139 18.8991 6.16151 18.39C4.3505 18.0414 0.747227 16.6949 0.00089168 14.7234V12.6922C0.00089168 5.68267 5.68178 0 12.6913 0H25.6857C25.6144 4.2114 25.7615 8.42548 26.1556 12.6208C26.4597 15.8469 27.2952 18.9393 30.3697 20.5719C33.2088 22.078 36.7042 21.5849 39.0556 19.3976C41.6067 17.0239 41.9223 13.0604 42.2032 9.80223C42.4199 7.2556 42.4332 4.70183 42.3762 2.14806C45.7681 4.42541 48.0018 8.29886 48.0018 12.6922H48.0009Z"
                  fill="#000000"
                />
                <path
                  d="M47.9998 29.4538V35.3095C47.9998 36.91 47.7029 38.441 47.1616 39.8508C44.0809 39.9364 40.9948 39.9426 37.9256 39.6341C34.8128 39.3211 30.3981 38.7549 30.0985 34.8173C29.9852 33.3362 30.7137 31.8676 31.9104 30.9946C33.3246 29.9629 35.3139 29.9656 36.9831 29.8274C40.6488 29.5233 44.3243 29.4039 47.9989 29.4547L47.9998 29.4538Z"
                  fill="#000000"
                />
              </g>
              <defs>
                <clipPath id="clip0_4614_93678">
                  <rect width="48" height={48} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="col-span-8 ">Nacho average roster machine.</div>
        </div>
      </div>
      <div className="mb-2 flex justify-center"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-6">
          <Input
            type="email"
            name="email"
            placeholder="Enter Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.email ? formik.errors.email : ""}
          />
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.password ? formik.errors.password : ""}
            placeholder="Enter Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleShowPassword}
                  state={showPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
        </div>
        {/* <div className="my-6 ml-auto">
          <p className="text-end">
            <span className="text-[#64748B] font-bold font-DM">
              Don’t have an account?
            </span>
            <Link
              to="/signup"
              className="font-DM text-secondary font-semibold text-md ml-1.5"
            >
              Click Here to Sign up
            </Link>
          </p>
        </div> */}
        <div className="my-5">
          <div className="text-lg font-DM font-semibold text-right text-secondary">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
        <div className="mt-8 mb-4">
          <Button
            disabled={isLoading}
            className="flex justify-center items-center"
            name="Submit"
          >
            {isLoading ? (
              <ThreeDots
                height={80}
                width="80"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            ) : (
              "Login"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
