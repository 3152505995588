import { useCallback, useEffect, useRef, useState } from "react";
import { DATE_RANGE, TIME_ZONE } from "../../constants";
import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";
import {
  useGetShiftsQuery,
  usePrefetch,
} from "../../api/shiftsApi";
import { formatDateAPI } from "../../utils/formatDate";
import { DateTime } from "luxon";
import { ShiftType } from "../../interface/shift";

export const sortShifts = (
  shiftA: {
    type:  ShiftType;
    time: { from: string; to: string };
  },
  shiftB: {
    type: ShiftType;
    time: { from: string; to: string };
  }
) => {
  if (shiftA.type === shiftB.type) {
    if (
      DateTime.fromISO(shiftA.time.from)
        .setZone(TIME_ZONE)
        .equals(DateTime.fromISO(shiftB.time.from).setZone(TIME_ZONE))
    ) {
      return (
        DateTime.fromISO(shiftA.time.to)
          .setZone(TIME_ZONE)
          .toJSDate()
          .getTime() -
        DateTime.fromISO(shiftB.time.to).setZone(TIME_ZONE).toJSDate().getTime()
      );
    }
    return (
      DateTime.fromISO(shiftA.time.from)
        .setZone(TIME_ZONE)
        .toJSDate()
        .getTime() -
      DateTime.fromISO(shiftB.time.from).setZone(TIME_ZONE).toJSDate().getTime()
    );
  }
  if (shiftA.type === "normal") {
    return -1;
  }
  if (shiftB.type === "normal") {
    return 1;
  }
  if (shiftA.type === "standBy" && shiftB.type === "onCall") {
    return -1;
  }
  if (shiftA.type === "onCall" && shiftB.type === "standBy") {
    return 1;
  }
  return -1;
};

/**
 * API route fetches shifts accross all locations.
 * @param param0 
 * @returns 
 */
export const useGetShifts = ({
  activeDate,
  prevDate,
  nextDate,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
}) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  const prevDateObj = useGetShiftsQuery(
    {
      date: formatDateAPI(prevDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId }
  );

  const activeDateObj = useGetShiftsQuery(
    {
      date: formatDateAPI(activeDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId }
  );

  const nextDateObj = useGetShiftsQuery(
    {
      date: formatDateAPI(nextDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId }
  );

  return {
    rawData: {
      prevDate: prevDateObj.data,
      activeDate: activeDateObj.data,
      nextDate: nextDateObj.data,
    },
    data:
      prevDateObj.data && activeDateObj.data && nextDateObj.data
        ? [
            [...(prevDateObj.data[activeLocationId] ?? [])].sort(sortShifts),
            [...(activeDateObj.data[activeLocationId] ?? [])].sort(sortShifts),
            [...(nextDateObj.data[activeLocationId] ?? [])].sort(sortShifts),
          ]
        : undefined,
    isLoading:
      prevDateObj.isLoading || activeDateObj.isLoading || nextDateObj.isLoading,
    isFetching:
      prevDateObj.isFetching ||
      activeDateObj.isFetching ||
      nextDateObj.isFetching,
    refetch: (type: "all" | "active" | "prev" | "next" = "active") => {
      if (type === "all" || type === "prev") {
        prevDateObj.refetch();
      }
      if (type === "all" || type === "active") {
        activeDateObj.refetch();
      }
      if (type === "all" || type === "next") {
        nextDateObj.refetch();
      }
    },
  };
};

export const useGetShiftsSingle = ({ activeDate }: { activeDate: Date }) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  const { data, isLoading, isFetching, refetch } = useGetShiftsQuery(
    {
      date: formatDateAPI(activeDate),
      seniority: activeSeniority,
      range: DATE_RANGE,
    },
    { skip: !activeLocationId }
  );

  return {
    data: data ? data[activeLocationId] : data,
    isLoading,
    isFetching,
    refetch,
  };
};

// move to prefetch context, keep it in its own hook
export const usePrefetchGetShifts = ({ activeDate }: { activeDate: Date }) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniority } = useSeniority();

  const prefetchGetShifts = usePrefetch("getShifts");

  const [trigger, setTrigger] = useState(false);
  const callbackRef = useRef<{
    callback: ((force: boolean) => void) | null;
    isFirst: boolean;
  }>({ callback: null, isFirst: true });

  callbackRef.current.callback = useCallback(
    (force: boolean) => {
      [-3, -2, 2, 3]
        .map((delta) =>
          DateTime.fromJSDate(activeDate)
            .setZone(TIME_ZONE)
            .plus({ day: delta })
            .toJSDate()
        )
        .forEach((date) => {
          prefetchGetShifts(
            {
              date: formatDateAPI(date),
              seniority: activeSeniority,
              range: DATE_RANGE,
            },
            { force }
          );
        });
    },
    [activeDate, activeSeniority, prefetchGetShifts]
  );

  useEffect(() => {
    callbackRef.current.callback?.(false);
  }, [activeDate, activeLocationId, activeSeniority]);

  useEffect(() => {
    if (!callbackRef.current.isFirst) {
      callbackRef.current.callback?.(true);
    }
  }, [trigger]);

  useEffect(() => {
    setTimeout(() => {
      callbackRef.current.isFirst = false;
    }, 30);
  }, []);

  return {
    triggerPrefetch: () => {
      setTrigger((trigger) => !trigger);
    },
  };
};
