const SearchTwo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M11.0667 12L6.86665 7.8C6.53332 8.06667 6.14998 8.27778 5.71665 8.43333C5.28332 8.58889 4.82221 8.66667 4.33332 8.66667C3.12221 8.66667 2.09721 8.24722 1.25832 7.40833C0.419429 6.56944 -1.52588e-05 5.54444 -1.52588e-05 4.33333C-1.52588e-05 3.12222 0.419429 2.09722 1.25832 1.25833C2.09721 0.419444 3.12221 0 4.33332 0C5.54443 0 6.56943 0.419444 7.40832 1.25833C8.24721 2.09722 8.66665 3.12222 8.66665 4.33333C8.66665 4.82222 8.58887 5.28333 8.43332 5.71667C8.27776 6.15 8.06665 6.53333 7.79998 6.86667L12 11.0667L11.0667 12ZM4.33332 7.33333C5.16665 7.33333 5.87498 7.04167 6.45832 6.45833C7.04165 5.875 7.33332 5.16667 7.33332 4.33333C7.33332 3.5 7.04165 2.79167 6.45832 2.20833C5.87498 1.625 5.16665 1.33333 4.33332 1.33333C3.49998 1.33333 2.79165 1.625 2.20832 2.20833C1.62498 2.79167 1.33332 3.5 1.33332 4.33333C1.33332 5.16667 1.62498 5.875 2.20832 6.45833C2.79165 7.04167 3.49998 7.33333 4.33332 7.33333Z"
        fill="#1D1B20"
      />
    </svg>
  );
};

export default SearchTwo