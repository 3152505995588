import { useEffect, useState } from "react"
import Input from "../Input/index"
import EyeToggle from "../Icons/Eye"
import Button from "../Button/index"
import { useFormik } from "formik"
import { useChangePasswordMutation } from "../../api/rosterApi"
import { toast } from "react-toastify"
import { useModal } from "../../hooks/useModal"

interface ResetPasswordModalProps {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const ResetPasswordModal = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const toggleNewPassword = () => {
    setNewPassword(!newPassword)
  }
  const toggleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword)
  }

  const [changePassword, { isLoading, isError, error, data, isSuccess }] =
    useChangePasswordMutation()

  const { closeModal } = useModal()

  useEffect(() => {
    if (isSuccess) {
      toast.success("Password changed successfully")
      closeModal()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      toast.error((error as any).data.message)
    }
  }, [isError])

  const formik = useFormik<ResetPasswordModalProps>({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors: Partial<ResetPasswordModalProps> = {}
      if (!values.currentPassword) {
        errors.currentPassword = "Required"
      }
      if (!values.newPassword) {
        errors.newPassword = "Required"
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Required"
      } else if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = "Password does not match"
      }

      return errors
    },

    onSubmit: async (values) => {
      await changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex bg-white flex-col w-full gap-6 outer-shadow max-w-md px-8 py-6 mx-auto rounded-xl"
    >
      <div className=" text-xl font-semibold">Change Password</div>
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <Input
            type={showPassword ? "text" : "password"}
            name="currentPassword"
            disabled={isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentPassword}
            errorMsg={
              formik.touched.currentPassword
                ? formik.errors.currentPassword
                : ""
            }
            placeholder="Current Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleShowPassword}
                  state={showPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
        </div>
        <div className="col-span-1">
          <Input
            type={newPassword ? "text" : "password"}
            name="newPassword"
            onChange={formik.handleChange}
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            errorMsg={
              formik.touched.newPassword ? formik.errors.newPassword : ""
            }
            placeholder="New Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleNewPassword}
                  state={newPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
        </div>
        <div className="col-span-1">
          <Input
            type={confirmPassword ? "text" : "password"}
            name="confirmPassword"
            onChange={formik.handleChange}
            disabled={isLoading}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            errorMsg={
              formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ""
            }
            placeholder="Confirm Password"
            icon={{
              content: (
                <EyeToggle
                  onClick={toggleConfirmPassword}
                  state={confirmPassword ? "closed" : "open"}
                />
              ),
              position: "right",
            }}
          />
        </div>
        <div className="col-span-1">
          <div className="flex items-center justify-center gap-8 mt-8">
            <Button
              type="button"
              disabled={isLoading}
              className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              className="flex justify-center text-center !rounded-xl"
              type="submit"
              onClick={() => {
                // onSubmit?.(groups)
                // closeModal()
              }}
            >
              {isLoading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ResetPasswordModal
