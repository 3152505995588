import { FC, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Fullscreen from "react-fullscreen-crossbrowser";
import { FiMinimize, FiMaximize } from "react-icons/fi";

import SideBarLayout from "../../layouts/Sidebar";
import { EstimationContextProvider } from "../../context/EstimationContext";
import { getDateFromHash, getDateRange } from "../../utils/date";
import DateScroll from "../../components/Estimation/DateScroll";
import SecondaryMonitor from "../../components/Estimation/SecondaryMonitor";
import PrimaryMonitor from "../../components/Estimation/PrimaryMonitor";
import { TopBar } from "../../components/NavBar/TopBarEstimation";
import ResponsiveWindow from "../../components/Estimation/ResponsiveWindow";
import DoctorFilterWindow from "../../components/Estimation/DoctorFilterWindow";
import { PrefetchContextProvider } from "../../context/PrefetchContext";
import EstimationDndContext from "../../context/EstimationDndContext";
import { RefetchContextProvider } from "../../context/RefetchContext";
import useGlobalLoading from "../../hooks/globalLoading";
import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";

const Estimation: FC = () => {
  const { dateHash } = useParams<{ dateHash: string }>();
  const [isFullscreenEnabled, setIsFullScreenEnableed] =
    useState<boolean>(false);
  const { activeId: activeLocationId } = useLocation();
  const [_, setSearchParams] = useSearchParams();
  const { activeId: activeSeniorityId } = useSeniority();

  const setEstimationParams = ({
    locationId,
    seniority,
  }: {
    locationId?: string;
    seniority?: number;
  }) =>
    setSearchParams({
      locationId: locationId ?? activeLocationId,
      seniority: seniority
        ? seniority.toString()
        : activeSeniorityId?.toString() ?? "1",
    });

  const globalLoading = useGlobalLoading();

  if (!dateHash) {
    return <>Invalid (No Date Hash)</>;
  }

  const { prevDate, activeDate, nextDate } = getDateRange(
    getDateFromHash(dateHash)
  );

  return (
    <Fullscreen
      enabled={isFullscreenEnabled}
      onChange={(isFullscreenEnabled: boolean) =>
        setIsFullScreenEnableed(isFullscreenEnabled)
      }
    >
      <RefetchContextProvider>
        <PrefetchContextProvider
          activeDate={activeDate}
          nextDate={nextDate}
          prevDate={prevDate}
        >
          <EstimationContextProvider>
            <EstimationDndContext
              activeDate={activeDate}
              nextDate={nextDate}
              prevDate={prevDate}
            >
              <SideBarLayout
                type="estimation"
                setParams={{
                  estimation: setEstimationParams,
                  dashboard: undefined,
                  shiftPoints: undefined,
                }}
              >
                <button
                  className="absolute right-2 text-secondary top-2 z-[999]"
                  onClick={() => setIsFullScreenEnableed(!isFullscreenEnabled)}
                >
                  {isFullscreenEnabled ? (
                    <FiMinimize className="w-7 h-7" />
                  ) : (
                    <FiMaximize className="w-7 h-7" />
                  )}
                </button>
                <div className="flex-grow flex flex-col">
                  <div className="pt-2">
                    <TopBar
                      setEstimationParams={setEstimationParams}
                      globalLoading={globalLoading}
                    />
                  </div>
                  <div className="flex-grow h-0 grid grid-rows-4 grid-cols-10 px-10 py-5">
                    <div className="col-span-6 row-span-2 flex">
                      <DateScroll
                        className="m-auto"
                        activeDate={activeDate}
                        nextDate={nextDate}
                        prevDate={prevDate}
                      ></DateScroll>
                    </div>
                    <div className="col-span-4 row-span-4 pl-10">
                      <div className="grid grid-cols-1 grid-rows-4 h-full p-0.5 gap-y-4">
                        <div className="col-span-1 row-span-2 py-5">
                          <ResponsiveWindow
                            activeDate={activeDate}
                            nextDate={nextDate}
                            prevDate={prevDate}
                          />
                        </div>
                        <div className="col-span-1 row-span-2">
                          <DoctorFilterWindow
                            activeDate={activeDate}
                            prevDate={prevDate}
                            nextDate={nextDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 row-span-2 p-1 ">
                      <div className="grid grid-cols-3 gap-4 h-full">
                        <div className="col-span-1 h-full overflow-auto">
                          <SecondaryMonitor
                            className="w-full flex flex-col"
                            prevDate={prevDate}
                          />
                        </div>
                        <div className="col-span-2 flex flex-col h-full overflow-auto">
                          <PrimaryMonitor
                            className="w-full flex flex-col"
                            activeDate={activeDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SideBarLayout>
            </EstimationDndContext>
          </EstimationContextProvider>
        </PrefetchContextProvider>
      </RefetchContextProvider>
    </Fullscreen>
  );
};

export default Estimation;
