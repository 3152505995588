import { useEffect, useMemo } from "react";
import { DateTime } from "luxon";

import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { generateDatesMonth } from "@/utils/generateDates";
import { NUMBER_OF_DOCTORS, TIME_ZONE } from "@/constants";
import { isDatesEqual } from "@/utils/date";

import Cell from "./Cell";

// Add react window along with infinite scroll

const Table = () => {
  const {
    month,
    year,
    activeDate,
    setActiveDate,
    isShiftTimeToggle,
    scrollRef,
    onScrollHandlers,
  } = useTableViewContext();

  const dates = useMemo(() => {
    return generateDatesMonth(month, year);
  }, [month, year]);

  const getIsActive = (date: Date) => {
    return Boolean(!activeDate || isDatesEqual(date, activeDate));
  };

  useEffect(() => {
    if (scrollRef && scrollRef.current.divTable) {
      scrollRef.current.divTable.addEventListener(
        "scroll",
        onScrollHandlers.table
      );
    }
    const elemCopy = scrollRef!.current.divTable;

    return () => {
      elemCopy?.removeEventListener("scroll", onScrollHandlers.table);
    };
  }, [onScrollHandlers.table, scrollRef]);

  return (
    <div className="w-full overflow-x-auto h-full bg-gray4 small-scrollbar-y">
      <table className="table-fixed border-spacing-x-[2px] border-separate h-full">
        <thead>
          <tr className="block">
            {dates.map((date, index) => {
              const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
              return (
                <th key={index}>
                  <div
                    className={`${
                      getIsActive(date)
                        ? "bg-pink1"
                        : dateTime.weekday === 6 || dateTime.weekday === 7
                        ? "bg-pink2"
                        : "bg-gray7"
                    } rounded-t-lg w-[135px] h-[60px] flex justify-center items-center relative text-white cursor-pointer`}
                    onClick={() => {
                      if (activeDate && isDatesEqual(date, activeDate)) {
                        setActiveDate(undefined);
                      } else {
                        setActiveDate(date);
                      }
                    }}
                  >
                    <div className="text-2xl">{dateTime.day}</div>
                    <div className="vertical-text -scale-100 absolute right-0">
                      {dateTime.toFormat("EEE")}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody
          className="block overflow-y-scroll h-full small-scrollbar"
          ref={(ref) => {
            scrollRef!.current.divTable = ref;
          }}
        >
          {Array(NUMBER_OF_DOCTORS)
            .fill(0)
            .map((_, index) => {
              return (
                <tr className="table" key={index}>
                  {dates.map((date, indexInner) => {
                    return (
                      <td key={indexInner}>
                        <div className="w-[135px] h-[60px] border-b-green12 border-b-[1px]">
                          <Cell
                            isActive={getIsActive(date)}
                            shifts={[0, 0]}
                            isOff={false}
                            isTimeShow={isShiftTimeToggle}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          <tr
            className="table"
            style={{ height: `calc(100% - ${60 * NUMBER_OF_DOCTORS}px)` }}
          >
            {dates.map((date, index) => {
              return (
                <td className="h-full" key={index}>
                  <div
                    className={`${
                      getIsActive(date) ? "bg-white" : "bg-gray8"
                    } w-[135px] h-full`}
                  ></div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
