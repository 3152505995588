import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import Typography from "../Typography";
import { DateTime } from "luxon";
import { TIME_ZONE } from "../../constants";

interface CalendarSwitcherProps {
  month: number;
  year: number;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  className?: string;
  buttonClass?: string;
  textClass?: string;
  iconClass?: string;
  onPrev?: () => void;
  onNext?: () => void;
}
const CalendarSwitcher = (props: CalendarSwitcherProps) => {
  const {
    disabledLeft = false,
    disabledRight = false,
    month,
    className,
    textClass,
    year,
    buttonClass,
    iconClass,
    onPrev,
    onNext,
  } = props;
  return (
    <div className={`flex items-center gap-x-4 p-2 ${className}`}>
      <button
        disabled={disabledLeft}
        className={`${
          buttonClass ?? "p-2 h-8 w-8"
        } flex justify-center items-center`}
        onClick={onPrev}
      >
        <HiChevronLeft className={iconClass} />
      </button>
      <Typography
        tag="span"
        className={`${textClass ?? "font-semibold whitespace-nowrap text-sm"}`}
      >
        {DateTime.fromObject({ year, month }, { zone: TIME_ZONE }).toFormat(
          "MMM yyyy"
        )}
      </Typography>
      <button
        disabled={disabledRight}
        className={`${
          buttonClass ?? "p-2 h-8 w-8"
        } flex justify-center items-center`}
        onClick={onNext}
      >
        <HiChevronRight className={iconClass} />
      </button>
    </div>
  );
};

export default CalendarSwitcher;
