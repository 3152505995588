import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Switch from "rc-switch";
import { InfinitySpin } from "react-loader-spinner";

import DoctorSearch from "../DoctorSearch";
import DraggableDoctorCard from "../DraggableDoctorCard.tsx";

import TagPill from "../../Pills/TagPill.tsx";
import HorizontalScrollContainer from "../../HorizontalScrollContainer";
import AllFilter from "../../Icons/AllFilter";
import RoundedSeniorityTag from "../../Icons/RoundedSeniorityTag";
import RoundedTags from "../../Icons/RoundedTags";
import TopToBottomArrow from "../../Icons/TopToBottomArrow";
import RoundedSeniority from "../../Icons/RoundedSeniority";

import { useSeniority } from "../../../store/seniority.state.tsx";
import { useLocation } from "../../../store/location.store.ts";
import { useRunOnChange } from "../../../hooks/runOnChange.tsx";
import { useEstDndContext } from "../../../hooks/context/useEstDndContext.tsx";
import { useEstimationContext } from "../../../hooks/context/useEstimationContext";
import useData from "./data.tsx";
import { getDateRange } from "../../../utils/date.ts";
import { useGetShifts } from "../../../hooks/estimation/useGetShifts.tsx";
import { ShiftType } from "../../../interface/shift.ts";

type DoctorFilterWindowProps = {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
};

const iconTransitionClasses = "transition-opacity duration-700 ease-in-out";

const pillActiveBgColors = {
  "3": "bg-[#DF9C28] ",
  "2": "bg-[#84ACE2] ",
  "1": "bg-[#E57041] ",
};

const DoctorFilterWindow = ({
  activeDate,
  prevDate,
  nextDate,
}: DoctorFilterWindowProps) => {
  const [showIcons, setShowIcons] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [triggerSearchReset, setTriggerSearchReset] = useState(false);
  const { activeDoctor } = useEstDndContext();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const {
    editShiftId,
    dataDoctor,
    doctorFilterSeniority,
    setDoctorFilterSeniority,
    setActiveTab,
    activeTab,
    setResponsiveWindow,
    setDataDoctor,
    setMonitorActiveId,
    autoAssign,
    setAutoAssign,
  } = useEstimationContext();

  useEffect(() => {
    inputRef.current?.focus();
  }, [doctorFilterSeniority, activeSeniorityId, activeLocationId]);

  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [activeGroups, setActiveGroups] = useState<
    { _id: string; subGroups: { _id: string; title: string }[] }[]
  >([]);
  const [activeSubGroups, setActiveSubGroups] = useState<{ _id: string }[]>([]);

  const { data: shiftsData } = useGetShifts({
    activeDate,
    prevDate,
    nextDate,
  });

  const index: number =
    editShiftId && shiftsData
      ? shiftsData.findIndex((shifts) =>
          shifts.find((shift) => shift._id === editShiftId)
        )
      : 1;

  const {
    isTagsLoading,
    tags,
    isGroupsLoading,
    groups,
    displayType,
    doctors,
    isDoctorsFetching,
    handleOnScheduleAdd,
  } = useData({
    ...getDateRange(
      { "0": prevDate, "1": activeDate, "2": nextDate }[
        index.toString() as "0" | "1" | "2"
      ]
    ),
    searchQuery,
    activeGroups,
    activeSubGroups,
    activeTags,
    setTriggerSearchReset,
  });

  const handleActiveTags = (val: string) => {
    if (activeTags.includes(val)) {
      setActiveTags(activeTags.filter((tag) => tag !== val));
    } else {
      setActiveTags([...activeTags, val]);
    }
  };

  const handleActiveGroups = (selectedGroup: any) => {
    const isGroupActive = activeGroups.some(
      (group) => group._id === selectedGroup.id
    );
    if (isGroupActive) {
      setActiveGroups(
        activeGroups.filter((group) => group._id !== selectedGroup.id)
      );
      setActiveSubGroups([]);
    } else {
      setActiveSubGroups([]);
      setActiveGroups([selectedGroup]);
    }
  };

  const handleActiveSubGroups = (selectedSubGroup: any) => {
    const isSubGroupActive = activeSubGroups.some(
      (subGroup) => subGroup._id === selectedSubGroup.id
    );
    if (isSubGroupActive) {
      setActiveSubGroups(
        activeSubGroups.filter(
          (subGroup) => subGroup._id !== selectedSubGroup.id
        )
      );
    } else {
      setActiveSubGroups([...activeSubGroups, selectedSubGroup]);
    }
  };

  const handleDoctorSearchFocus = () => {
    setShowIcons(false);
  };

  const handleDoctorSearchBlur = () => {
    setShowIcons(true);
  };

  useRunOnChange(triggerSearchReset, () => {
    setSearchQuery("");
  });

  return (
    <div className="flex flex-col bg-[#FEFDF8] h-full rounded-xl w-full">
      <div className={`flex items-center gap-x-2`}>
        <DoctorSearch
          className={`flex-grow transition-all duration-700 ease-in-out`}
          onFocus={handleDoctorSearchFocus}
          onBlur={handleDoctorSearchBlur}
          setSearchValue={setSearchQuery}
          searchValue={searchQuery}
          inputRef={inputRef}
        />
        <div
          className={`flex items-center ${showIcons ? "gap-x-2" : "gap-x-0"}`}
        >
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "suggested")
                ? "opacity-0"
                : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "suggested") && (
              <AllFilter
                className="cursor-pointer"
                fill={activeTab === "suggested" ? "fill-secondary" : ""}
                iconColor={activeTab === "suggested" ? "fill-white" : ""}
                onClick={() => {
                  if (activeTab !== "suggested") {
                    setActiveTab("suggested");
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                    setActiveTags([]);
                  }
                }}
              />
            )}
          </div>
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "seniority")
                ? "opacity-0"
                : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "seniority") && (
              <div className="relative">
                <RoundedSeniority
                  className="cursor-pointer"
                  fill={activeTab === "seniority" ? "fill-secondary" : ""}
                  iconColor={activeTab === "seniority" ? "fill-white" : ""}
                  onClick={() => {
                    if (activeTab !== "seniority") {
                      setActiveTab("seniority");
                      setActiveGroups([]);
                      setActiveSubGroups([]);
                      setActiveTags([]);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={`${iconTransitionClasses} ${
              !(showIcons || activeTab === "tags") ? "opacity-0" : "opacity-100"
            }`}
          >
            {(showIcons || activeTab === "tags") && (
              <RoundedTags
                className="cursor-pointer"
                fill={activeTab === "tags" ? "fill-white" : ""}
                fillbg={activeTab === "tags" ? "fill-secondary" : ""}
                onClick={() => {
                  if (activeTab !== "tags") {
                    setActiveTab("tags");
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <AnimatePresence>
          {activeTab === "seniority" ? (
            <motion.div
              key="seniority-buttons"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{ type: "interia" }}
              className={`flex items-center mt-3 ml-3 gap-x-2`}
            >
              <div className={`${iconTransitionClasses}`}>
                <RoundedSeniorityTag
                  className="cursor-pointer"
                  fill={doctorFilterSeniority === 1 ? "senior" : "white"}
                  stroke={"senior"}
                  onClick={() => {
                    setResponsiveWindow({
                      type: "dateView",
                      props: {},
                    });
                    setDataDoctor(undefined);
                    setDoctorFilterSeniority(1);
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                  }}
                />
              </div>
              <div className={`${iconTransitionClasses}`}>
                <RoundedSeniorityTag
                  className="cursor-pointer"
                  fill={doctorFilterSeniority === 2 ? "midlevel" : "white"}
                  stroke={"midlevel"}
                  onClick={() => {
                    setResponsiveWindow({
                      type: "dateView",
                      props: {},
                    });
                    setDataDoctor(undefined);
                    setDoctorFilterSeniority(2);
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                  }}
                />
              </div>
              <div className={`${iconTransitionClasses}`}>
                <RoundedSeniorityTag
                  className="cursor-pointer"
                  fill={doctorFilterSeniority === 3 ? "junior" : "white"}
                  stroke={"junior"}
                  onClick={() => {
                    setResponsiveWindow({
                      type: "dateView",
                      props: {},
                    });
                    setDataDoctor(undefined);
                    setDoctorFilterSeniority(3);
                    setActiveGroups([]);
                    setActiveSubGroups([]);
                  }}
                />
              </div>
            </motion.div>
          ) : (
            <motion.div></motion.div>
          )}
        </AnimatePresence>
        <div className="flex bg-secondary h-fit p-1.5 rounded-md w-[103px] justify-between mt-2 custom-rc-estimation">
          <Switch
            onChange={() => setAutoAssign((autoAssign) => !autoAssign)}
            checked={autoAssign}
          />
          <div className="bg-white text-[0.65rem] font-bold rounded text-center w-[45px] leading-1">
            {autoAssign ? "auto" : "manual"}
          </div>
        </div>
      </div>
      <div className="bg-gray6 flex-grow rounded-xl mt-4 pr-3 flex flex-col overflow-hidden">
        <div>
          {activeTab === "tags" && (
            <HorizontalScrollContainer
              isLoading={isTagsLoading}
              className="p-3"
            >
              {tags?.map((tag: { value: string }, i: number) => (
                <TagPill
                  key={i}
                  name={tag.value}
                  className={"w-fit"}
                  activeBgColor={"bg-secondary"}
                  isActive={activeTags.includes(tag.value)}
                  onClick={() => handleActiveTags(tag.value)}
                />
              ))}
            </HorizontalScrollContainer>
          )}
          {activeTab === "seniority" && (
            <HorizontalScrollContainer
              isLoading={isGroupsLoading}
              className="p-3"
            >
              {groups?.map(
                (group: { _id: string; title: string; subGroups: any[] }) => (
                  <TagPill
                    key={group._id}
                    name={group.title}
                    className={"w-fit flex gap-x-4 items-center"}
                    isActive={activeGroups.some(
                      (activeGroup) => activeGroup._id === group._id
                    )}
                    activeBgColor={
                      pillActiveBgColors[
                        String(doctorFilterSeniority) as "1" | "2" | "3"
                      ]
                    }
                    onClick={() => handleActiveGroups(group)}
                    children={
                      group.subGroups.length > 0 && (
                        <TopToBottomArrow
                          fill={
                            activeGroups.some(
                              (activeGroup) => activeGroup._id === group._id
                            )
                              ? ""
                              : "fill-black3"
                          }
                        />
                      )
                    }
                  />
                )
              )}
            </HorizontalScrollContainer>
          )}
          {activeTab === "seniority" && (
            <HorizontalScrollContainer className="px-3">
              {activeGroups.map((group) => {
                if (group.subGroups.length > 0) {
                  return group.subGroups.map(
                    (subGroup: { _id: string; title: string }) => (
                      <TagPill
                        key={subGroup._id}
                        name={subGroup.title}
                        className={"w-fit "}
                        activeBgColor={
                          pillActiveBgColors[
                            String(doctorFilterSeniority) as "1" | "2" | "3"
                          ]
                        }
                        isActive={activeSubGroups.some(
                          (activeSubGroup) =>
                            activeSubGroup._id === subGroup._id
                        )}
                        onClick={() => handleActiveSubGroups(subGroup)}
                      />
                    )
                  );
                }
                return null;
              })}
            </HorizontalScrollContainer>
          )}
        </div>
        {displayType === "loading" ? (
          <div className="flex justify-center items-center h-[50%]">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : displayType === "displayData" ? (
          <div
            className={`px-4 py-4 ${
              activeDoctor ? "overflow-y-hidden" : "overflow-y-scroll"
            } overflow-x-hidden small-scrollbar flex-grow`}
          >
            <div className="grid grid-cols-2 gap-4">
              {doctors.map(
                (doctor: {
                  tags: any;
                  group: any;
                  subGroup: any;
                  efficiencyScore: number;
                  experience: number;
                  canAssign: boolean;
                  _id: string;
                  assignedShiftType: ShiftType | "none";
                  user: {
                    email: string;
                    name: string;
                    phoneNumber?: string;
                  };
                  availability: "warning" | "available" | "unavailable";
                  seniority: any;
                }) => (
                  <DraggableDoctorCard
                    key={doctor._id}
                    type={
                      editShiftId && activeTab !== "tags"
                        ? "doctor-filter"
                        : "doctor-filter-no-shift"
                    }
                    doctorId={doctor._id}
                    doctor={doctor}
                    name={doctor.user.name}
                    status={doctor.availability}
                    seniority={String(doctor.seniority?.id ?? doctor.seniority) as "1" | "2" | "3"}
                    canAssign={doctor.canAssign}
                    canDrag={doctor.canAssign || !editShiftId}
                    isActive={doctor._id === dataDoctor?.id}
                    assignedShiftType={doctor.assignedShiftType}
                    onClickHandler={() => {
                      if (dataDoctor && dataDoctor.id === doctor._id) {
                        setDataDoctor(undefined);
                        setResponsiveWindow({
                          type: "dateView",
                          props: {},
                        });
                      } else {
                        setMonitorActiveId(undefined);
                        setDataDoctor({
                          id: doctor._id,
                          seniority: doctor.seniority?.id ?? doctor.seniority,
                        });
                        setResponsiveWindow({
                          type: "userData",
                          props: {
                            name: doctor.user.name,
                            email: doctor.user.email,
                            phoneNumber: doctor.user.phoneNumber,
                            efficiency: doctor.efficiencyScore,
                            experience: doctor.experience,
                            groups: doctor.group ? [doctor.group] : [],
                            subGroups: doctor.subGroup ? [doctor.subGroup] : [],
                            tags: doctor.tags,
                          },
                        });
                      }
                    }}
                    isFetching={isDoctorsFetching}
                    onScheduleAdd={handleOnScheduleAdd}
                  />
                )
              )}
            </div>
          </div>
        ) : displayType === "noData" ? (
          <div className="flex justify-center items-center h-full font-medium">
            No Doctors Found
          </div>
        ) : (
          <div className="flex justify-center items-center h-full font-medium">
            Please Select a Shift
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorFilterWindow;
