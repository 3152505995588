import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const preferencesApi = createApi({
  reducerPath: "preferencesApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  tagTypes: ["ScheduleLabels"],
  endpoints: (builder) => ({
    getScheduleLabels: builder.query<any, any>({
      query: ({ seniority }) => ({
        url: `/preferences/schedule-labels?seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["ScheduleLabels"],
    }),
    createScheduleLabel: builder.mutation<any, any>({
      query: ({ label, shortLabel, seniority }) => ({
        url: `/preferences/schedule-label`,
        method: "POST",
        body: {
          label,
          shortLabel,
          seniority,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateScheduleLabel: builder.mutation<any, any>({
      query: ({ scheduleLabelId, label, shortLabel }) => ({
        url: `/preferences/update/schedule-label`,
        method: "POST",
        body: {
          payload: {
            ...(label ? { label } : {}),
            ...(shortLabel ? { shortLabel } : {}),
          },
          scheduleLabelId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteScheduleLabel: builder.mutation<any, any>({
      query: ({ scheduleLabelId }) => ({
        url: `/preferences/delete/schedule-label`,
        method: "POST",
        body: {
          scheduleLabelId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getShiftPoints: builder.query<any, any>({
      query: ({ seniority, locationId }) => ({
        url: `/preferences/shift-points?seniority=${seniority}&locationId=${locationId}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateShiftPoints: builder.mutation<any, any>({
      query: ({ dayOfWeek, holidays, shiftPointsIds }) => ({
        url: `/preferences/update/shift-points`,
        method: "POST",
        body: { dayOfWeek, holidays, shiftPointsIds },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetScheduleLabelsQuery,
  useCreateScheduleLabelMutation,
  useUpdateScheduleLabelMutation,
  useDeleteScheduleLabelMutation,
  useGetShiftPointsQuery,
  useUpdateShiftPointsMutation,
} = preferencesApi;
